import React, { useRef } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi';

import * as S from './styles';
import Input from '../Input';
import Select from '../Select';
import { optionsEntity } from '../../pages/RegisterUser/options';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    color: '#000000',
    borderRadius: '0',
    width: '100%',
    maxWidth: '100rem',
    border: 'none',
    padding: '0',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
};

type SubmitFormData = {
  name: string;
  whatsapp: string;
  email: string;
  entity: string;
  permissionContact: boolean;
};

export default function RegisterUserModal() {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  async function handleTestSubmit(data: SubmitFormData) {
    const page = localStorage.getItem('@Cuid:page');
    const city = localStorage.getItem('@Cuid:city');

    const formattedFormData = {
      name: data.name,
      whatsapp: data.whatsapp,
      email: data.email,
      entity: data.entity,
      city: city,
      authorization: data.permissionContact,
    };
    localStorage.setItem(
      '@Cuid:contratante',
      JSON.stringify(formattedFormData),
    );

    await api.post('/employers', {
      name: data.name,
      email: data.email,
      whatsapp: data.whatsapp,
      entity: data.entity,
      city: city,
      authorization: data.permissionContact,
    });

    fetch('https://ntfy.sh/202211_NOTIFICATIONS_ABOUT_CUID', {
      method: 'POST',
      body: `CADASTRO CONTRATANTE: NOME: ${data.name}, WHATSAPP: ${data.whatsapp}, CIDADE: ${city}`,
      headers: {
        Priority: '2',
        Tags: 'loudspeaker',
      },
    });

    // redirect to previous link
    if (page === null) {
      history.push('/'); // if no possible get the previous link
    } else {
      history.push(page); // move to previous link
    }

    const existsAccount = localStorage.getItem('@Cuid:contratante');

    if (!existsAccount || !city) {
    } else {
      const { name, entity, whatsapp, city } = JSON.parse(existsAccount);
      // Send notify
      fetch('https://ntfy.sh/202309_SEARCHES_IN_CUID_APP', {
        method: 'POST',
        body: `(${entity}) - ${name} esta buscando por profissionais em ${city}.`,
        headers: {
          Actions: `view, Abrir Whatsapp, https://wa.me/55${whatsapp}?text=Ol%C3%A1!%20Precisa%20de%20ajuda%20para%20encontrar%20o%20profissional%20ideal?`,
        },
      });
    }
  }

  return (
    <Modal
      isOpen={true}
      // onAfterOpen={afterOpenModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <S.Container>
        <S.CloseButton type="button" onClick={() => window.history.back()}>
          <FiX />X
        </S.CloseButton>

        <Form ref={formRef} onSubmit={handleTestSubmit}>
          <h2>Criar conta</h2>
          <p>
            Crie sua conta e tenha acesso a mais de 600 profissionais em todo o
            Brasil.
          </p>
          <br />

          <label htmlFor="name">Nome completo</label>
          <Input
            className="input-box"
            id="name"
            name="name"
            type="text"
            placeholder="ex: João Pedro"
            required
          />

          <label htmlFor="whatsapp">Whatsapp</label>
          <Input
            className="input-box"
            id="whatsapp"
            name="whatsapp"
            type="tel"
            placeholder="ex: (11) 99999-9999"
            maxLength={15}
            required
          />

          <label htmlFor="email">Email</label>
          <Input
            className="input-box"
            id="email"
            name="email"
            type="email"
            placeholder="ex: seu@email.com.br"
            required
          />

          <label htmlFor="entity">Qual é o seu perfil?</label>

          <Select
            id="entity"
            name="entity"
            defaultValue={optionsEntity[0]}
            options={optionsEntity}
          />

          <S.CheckBoxWrapper>
            <div id="permissionContact">
              <Input
                className="input-box"
                id="permissionContact"
                name="permissionContact"
                type="checkbox"
                value="true"
                required
              />
            </div>
            <label htmlFor="checkbox">
              Autorizo a Cuid a entrar em contato comigo para me auxiliar na
              busca por um profissional.
            </label>
          </S.CheckBoxWrapper>

          <S.SubmitButton type="submit">Enviar</S.SubmitButton>
        </Form>
      </S.Container>
    </Modal>
  );
}
