import styled, { css, keyframes } from 'styled-components';

import { shade } from 'polished';

const apperFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;

export const Content = styled.div`
  max-width: 66rem;
  width: 100%;
  padding: 0 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AnimationContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${apperFromLeft} 1s;

    img {
      max-width: 30rem;
      max-height: 20.6rem;
    }

    form {
      margin: 3.2rem 0;
      max-width: 36rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;

      input {
        border: .1rem solid rgb(231, 229, 229);
      }

      h1 {
        margin-bottom: 2.5rem;
        color: ${theme.colors['blue-shade-700']};
      }

      a {
        color: ${theme.colors['blue-shade-700']};
        display: block;
        margin-top: 2.5rem;
        text-decoration: none;

        transition: color 0.2s;

        &:hover {
          color: ${shade(0.3, theme.colors['blue-shade-700'])};
        }
      }
    }

    > a {
      color: ${theme.colors['green-900']};
      display: block;
      margin-top: 16px;
      text-decoration: none;
      transition: color 0.2s;
      display: flex;
      align-items: center;

      svg {
        margin-right: 1.8rem;
      }

      &:hover {
        color: ${shade(0.2, theme.colors['green-900'])};
      }
  }
  `}
`;
