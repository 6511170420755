import L from 'leaflet';

import mapPinHouseImg from '../images/partners_house/IconPinHouse.png';

const mapIconHouse = L.icon({
  iconUrl: mapPinHouseImg,
  iconSize: [50, 50],
  iconAnchor: [29, 68],
  popupAnchor: [105, -20],
});


export default mapIconHouse;
