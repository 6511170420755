import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import axios from 'axios';
import * as Yup from 'yup';
import { cpf } from 'cpf-cnpj-validator';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Select from '../../components/Select';
import { TextArea } from '../../components/TextareaComponent';
import CheckboxInput from '../../components/CheckboxInput';

import getValidationErrors from '../../utils/getValidationsErrors';
import { formatCurrency } from '../../utils/mask';
import api from '../../services/api';

import {
  optionsAvailability,
  optionsOccupation,
  optionsGenre,
} from './options';

import * as S from './styles';
import { useToast } from '../../hooks/toast';
import moment from 'moment';

const warningText = 'número máximo de caracteres excedido!';

type OptionType = {
  value: string;
  label: string;
};

interface CheckboxOption {
  id: string;
  value: string;
  label: string;
}

type CepMessage = {
  key: 'valid' | 'invalid' | '';
  message: string;
};

type SubmitFormData = {
  name: string;
  genre: string;
  email: string;
  password: string;
  whatsapp: string;
  cpf: string;
  zip: string;
  address: string;
  numberHouse: string;
  neighborhood: string;
  city: string;
  uf: string;
  longitude: string | number;
  latitude: string | number;
  birthday: string;
  bio: string;
  availability: string;
  formation: boolean;
  occupation: string;
  cost: string;
  checkbox?: boolean;
};

interface CaregiverLocation {
  responseObject: {
    longitude: number;
    latitude: number;
    zipcode: string;
    venue: string;
    neighborhood: string;
    city: string;
    state: string;
  };
}

const checkboxOptions: CheckboxOption[] = [
  {
    id: 'checkbox',
    value: 'checkbox',
    label: ``,
  },
];

export default function RegisterUser() {
  const [zip, setZip] = useState('');
  const [address, setAddress] = useState<string>('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');
  const [longitude, setLongitude] = useState<number | string>();
  const [latitude, setLatitude] = useState<number | string>();
  const [formation, setFormation] = useState(true);
  const [costValue, setCostValue] = useState('');
  const [isActiveCoren, setIsActiveCore] = useState(false);
  const [bio, setBio] = useState('');
  const [birthday, setBirthday] = useState('');
  const [occupation, setOccupation] = useState('');

  const [userCPF, setUserCPF] = useState('');
  const [cpfErrorMessage, setCpfErrorMessage] = useState('');
  const [parceiro, setParceiro] = useState('Cuid');
  const [avgCostPerShift, setAvgCostPerShift] = useState('');
  const [isActiveAvgCostInfo, setIsActiveAvgCostInfo] = useState(true);

  const [isErrored, setIsErrored] = useState<CepMessage>({
    key: '',
    message: '',
  });

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const initialFormDatas = {
    address,
    city,
    neighborhood,
    uf,
  };

  const loadLocationData = useCallback(() => {
    axios
      .get<CaregiverLocation>(
        `https://api.famyle.com/api/locations/zipcode/${zip}`,
      )
      .then(response => {
        const { longitude, latitude, venue, neighborhood, city, state } =
          response.data.responseObject;

        setAddress(venue);
        setCity(city);
        setNeighborhood(neighborhood);
        setUf(state);
        setLatitude(latitude);
        setLongitude(longitude);
      });
  }, [zip]);

  useEffect(() => {
    async function checkIfCepExists() {
      setIsErrored({ key: '', message: '' });
      const cep = zip.replace(/\D/gi, '');

      axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(response => {
        if (response.data.erro === true) {
          setIsErrored({
            key: 'invalid',
            message: 'Cep inválido!',
          });
        } else {
          loadLocationData();
        }
      });
    }

    checkIfCepExists();
  }, [zip, loadLocationData]);

  useEffect(() => {
    function validateCPF() {
      const result = cpf.isValid(userCPF);

      if (!result && userCPF.length > 1) {
        setCpfErrorMessage('CPF inválido');
        return;
      }

      setCpfErrorMessage('');
      const cpfFormatted = cpf.format(userCPF);

      setUserCPF(cpfFormatted);
    }

    validateCPF();
  }, [userCPF]);

  useEffect(() => {
    // Get the URLSearchParams object from the current URL
    const params = new URLSearchParams(window.location.search);

    // Get the value of the 'parceiro' parameter from the URL
    const parceiro = params.get('parceiro');

    if (!parceiro) {
      setParceiro('Cuid');
    } else {
      setParceiro(parceiro);
    }
  }, []);

  async function handleTestSubmit(data: SubmitFormData) {
    const formattedFormData = {
      name: data.name,
      genre: data.genre,
      email: data.email,
      password: data.password,
      address: data.address,
      numberHouse: data.numberHouse,
      neighborhood: data.neighborhood,
      city: data.city,
      uf: data.uf.toUpperCase(),
      birthday: data.birthday,
      bio: data.bio,
      availability: data.availability,
      occupation: data.occupation,
      cost: data.cost.toString().replace(/\D/gi, ''),
      cpf: data.cpf.replace(/\D/gi, ''),
      whatsapp: data.whatsapp.replace(/\D/gi, ''),
      zip: data.zip.replace(/\D/gi, ''),
      latitude,
      longitude,
      formation,
      origin: parceiro,
    };

    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .matches(
            /^[a-zA-Z\wÀ-ú]+(?:\s[a-zA-Z\wÀ-ú]+)+$/,
            'Digito o nome completo',
          )
          .trim()
          .required('Nome é obrigatório!'),
        coren: Yup.string(),
        email: Yup.string()
          .email('Entre com um e-mail válido!')
          .required('E-mail é obrigatório!'),
        password: Yup.string()
          .min(6, 'No mínimo 6 digitos!')
          .required('Senha é obrigatória!'),
        whatsapp: Yup.string().required('Whatsapp é obrigatório'),
        cpf: Yup.string()
          .max(11, 'No máximo 11 digitos!')
          .required('CEP é obrigatório!'),
        zip: Yup.string()
          .max(8, 'No máximo 8 digitos!')
          .required('CEP é obrigatório!'),
        address: Yup.string()
          .max(100, 'número máximo de caracteres excedido!')
          .required('Endereço é obrigatório!'),
        numberHouse: Yup.string()
          .max(10, 'número máximo de caracteres excedido!')
          .required('número é obrigatório!'),
        neighborhood: Yup.string()
          .max(50, warningText)
          .required('Bairro é obrigatório!'),
        city: Yup.string()
          .max(50, warningText)
          .required('Cidade é obrigatório!'),
        uf: Yup.string().max(10, warningText).required('Estado é obrigatório!'),
        birthday: Yup.string()
          .test('Validate age', 'É preciso ser maior de 18 anos', value => {
            return moment().diff(moment(value), 'days') >= 6570; // 6570 days in 18 years
          })
          .required('Data de nascimento é obrigatório!'),
        bio: Yup.string()
          .min(120, 'No mínimo 120 catacteres!')
          .required('conte um pouquinho sobre você!'),
        occupation: Yup.string().required('selecione uma das opções!'),
        cost: Yup.string().required('informe o valor do plantão!'),
        availability: Yup.string().required('selecione uma das opções!'),
        formation: Yup.boolean(),
        genre: Yup.string().required('selecione uma das opções!'),
      });

      await schema.validate(formattedFormData, { abortEarly: false });

      await api.post('cadastro', formattedFormData);

      const { name, occupation, email } = formattedFormData;

      fetch('https://ntfy.sh/202211_NOTIFICATIONS_ABOUT_CUID', {
        method: 'POST',
        body: `Novo cadastro realizado:  Nome: ${name}, Profissional: ${occupation}, Email: ${email}`,
        headers: {
          Priority: '2',
          Tags: 'loudspeaker',
        },
      });

      history.push('/cadastro-realizado');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          'Ocorreu um erro ao realizar o seu cadastro, tente novamente.',
      });
    }
  }

  function handleToggle() {
    setFormation(prevState => !prevState);
  }

  function handleChangeIsActiveAvgCostInfo() {
    if (!city) {
      alert('Informe seu endereço para usar a calculadora!');
      return;
    }
    setIsActiveAvgCostInfo(true);
  }

  async function getAvgCostPerShift() {
    const response = await api.get(
      `cadastro/plantoes/${city}?ocupacao=${occupation}`,
    );

    const avgCostPerShift = response.data;

    const formattedAmount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(avgCostPerShift / 100);

    setAvgCostPerShift(formattedAmount);
  }

  useEffect(() => {
    getAvgCostPerShift();
  }, [occupation]);

  return (
    <S.Container>
      <Header path="/login" />

      <S.Content>
        <Form
          ref={formRef}
          initialData={initialFormDatas}
          onSubmit={handleTestSubmit}
        >
          <fieldset>
            <legend>Perfil</legend>

            <label htmlFor="name">Nome completo</label>
            <Input id="name" name="name" />

            <label htmlFor="genre">Gênero</label>
            <Select
              id="genre"
              name="genre"
              defaultValue={optionsGenre[0]}
              options={optionsGenre}
            />

            <label htmlFor="cpf">
              CPF <span>use somente números</span>
            </label>
            <Input
              id="cpf"
              name="cpf"
              maxLength={14}
              placeholder="ex: 000.000.000-00"
              value={userCPF}
              onChange={e => setUserCPF(e.target.value)}
              cpfErrorMessage={cpfErrorMessage}
            />

            <label htmlFor="whatsapp">
              Whatsapp <span>use somente números</span>
            </label>
            <Input
              id="whatsapp"
              name="whatsapp"
              mask="phone"
              maxLength={15}
              placeholder="ex: (11) 91234-5678"
            />

            <label htmlFor="zip">
              CEP<span>use somente números</span>
            </label>
            <Input
              id="zip"
              name="zip"
              mask="cep"
              cepValidate={isErrored}
              maxLength={9}
              value={zip}
              onChange={e => setZip(e.target.value)}
            />

            <label htmlFor="address">Endereço</label>
            <Input
              id="address"
              name="address"
              readOnly={!!initialFormDatas.address}
            />

            <label htmlFor="numberHouse">Número</label>
            <Input id="numberHouse" name="numberHouse" maxLength={10} />

            <label htmlFor="neighborhood">Bairro</label>
            <Input
              id="neighborhood"
              name="neighborhood"
              readOnly={!!initialFormDatas.neighborhood}
            />

            <label htmlFor="city">Cidade</label>
            <Input id="city" name="city" readOnly={!!initialFormDatas.city} />

            <label htmlFor="uf">Estado</label>
            <Input
              id="uf"
              name="uf"
              placeholder="ex: SP"
              readOnly={!!initialFormDatas.uf}
            />

            <label htmlFor="birthday">Data de Nascimento</label>
            <Input
              type="date"
              id="birthday"
              name="birthday"
              data-date-format="MM/DD/YYYY"
              pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
              required
              value={birthday}
              onChange={e => {
                setBirthday(e.target.value);

                if (moment().diff(moment(e.target.value), 'days') < 6570) {
                  formRef.current?.setFieldError(
                    'birthday',
                    'É preciso ser maior de 18 anos',
                  );
                } else {
                  formRef.current?.setFieldError('birthday', '');
                }
              }}
            />

            <label htmlFor="bio">
              Biografia <span>Máximo de 300 caracteres</span>
            </label>
            <TextArea
              id="name"
              name="bio"
              maxLength={300}
              value={bio}
              onChange={e => {
                if (e.target.value.length > 1 && e.target.value.length < 120) {
                  const charactersLeft = 120 - e.target.value.length;
                  const warnMessage = `No mínimo ${charactersLeft} catacteres!`;

                  formRef.current?.setFieldError('bio', warnMessage);
                } else {
                  formRef.current?.setFieldError('bio', '');
                }

                setBio(e.target.value);
              }}
            />
          </fieldset>

          <fieldset>
            <legend>Profissional</legend>

            <label htmlFor="occupation">Área de trabalho</label>
            <Select
              id="occupation"
              name="occupation"
              defaultValue={optionsOccupation[0]}
              options={optionsOccupation}
              onChange={selectedOption => {
                // It turns available COREN field if Auxiliar de enfermagem is selected
                const value = (selectedOption as OptionType).value;

                setIsActiveCore(value === optionsOccupation[1].value);
                setOccupation(value);
              }}
            />

            {isActiveCoren && ( // It's true if Auxiliar de enfermagem was selected
              <>
                <label htmlFor="coren">COREN</label>
                <Input id="coren" name="coren" max={10} />
              </>
            )}

            <label htmlFor="cost">
              Valor do seu plantão <br />
              <span>Considere um plantão de 12h</span>
            </label>
            <small>
              <button
                style={{
                  background: '#04d361',
                  color: 'black',
                  cursor: 'pointer',
                  padding: '1rem',
                  borderRadius: '8px',
                  marginBottom: '1rem',
                }}
                type="button"
                onClick={() => {
                  handleChangeIsActiveAvgCostInfo();
                  getAvgCostPerShift();
                }}
              >
                Ver média de plantão
              </button>
            </small>
            <small style={{ color: 'black' }}>
              {isActiveAvgCostInfo ? (
                <p>
                  {`Um plantão médio de 12h para um ${
                    occupation ? occupation : 'Profissional'
                  } em ${city}, é de: ${avgCostPerShift}`}
                </p>
              ) : (
                ''
              )}
            </small>
            <Input
              id="cost"
              name="cost"
              value={formatCurrency(costValue)}
              onChange={e => setCostValue(e.target.value)}
            />

            <label htmlFor="availability">
              Qual a sua disponibilidade para trabalho?
            </label>
            <Select
              id="availability"
              name="availability"
              defaultValue={optionsAvailability[0]}
              options={optionsAvailability}
            />

            <label htmlFor="formation">
              Você tem formação em cuidados de pessoas?
            </label>

            <S.WrapperToggleButton className="button-select">
              <button
                type="button"
                className={formation ? 'active' : ''}
                onClick={handleToggle}
              >
                Sim
              </button>
              <button
                type="button"
                className={!formation ? 'active' : ''}
                onClick={handleToggle}
              >
                Não
              </button>
            </S.WrapperToggleButton>
          </fieldset>

          <fieldset>
            <legend>Criar conta</legend>

            <label htmlFor="email">Email</label>
            <Input id="email" name="email" type="email" />

            <label htmlFor="password">Senha</label>
            <Input id="password" name="password" type="password" />

            <CheckboxInput name="checkbox" options={checkboxOptions} />
          </fieldset>

          <S.SubmitButton type="submit">Salvar</S.SubmitButton>
        </Form>
      </S.Content>
    </S.Container>
  );
}
