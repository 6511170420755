import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import api from '../../../services/api';

import { Button, Container, Description, Product, Section } from './styles';

const stripePromise = loadStripe(
  'pk_live_51IcJg5FxYI3sgKUsiC2NoBcCJoeWz2bep1hQZ4ZGj0onr7F8gH8koJoBijSzWIry3k7VLiOByKnn7v426j47zqRT00qxUoWYF6',
);

const PaymentMonthly: React.FC = () => {
  const handleClick = async (_event: any) => {
    const stripe = await stripePromise;

    const response = await api.post('/create-checkout-session', {
      method: 'POST',
    });

    const session = await response.data;
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe?.redirectToCheckout({
      sessionId: session.id,
    });

    if (result?.error) {
      alert(
        'Houve um problema com o seu pagamento! Por favor, tente novamente.',
      );
    }
  };

  return (
    <>
      <Container>
        <Section>
          <Product>
            <img src="https://i.imgur.com/EHyR2nP.png" alt="Plano Basic" />
            <Description>
              <h1>Plano Mensal Cuid</h1>
              <h3>R$ 9,90 / mês</h3>
            </Description>
          </Product>
          <Button type="button" role="link" onClick={handleClick}>
            Assinar
          </Button>
        </Section>
      </Container>
    </>
  );
};

export default PaymentMonthly;
