import React, { useEffect, useRef, InputHTMLAttributes } from "react";
import { useField } from "@unform/core";

import * as S from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: {
    id: string;
    value: string;
    label: string;
  }[];
}

const CheckboxInput: React.FC<Props> = ({ name, options, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <S.Container>
      {options.map(option => (
        <label key={option.id}>
          <input
            defaultChecked={defaultValue.includes(option.id)}
            // ref={ref => {
            //   inputRefs.current.push(ref as HTMLInputElement);
            // }}
            value={option.value}
            type="checkbox"
            {...rest}
          />
          {option.label}
        </label>
      ))}
      <p>
        Ao clicar em salvar, você assume que leu e concorda com os
        <a
          href="https://cuid.com.br/termos-de-uso.html"
          target="_blank"
          rel="noopener noreferrer"
        >termos de uso</a>
        da plataforma.
      </p>
    </S.Container>
  );
};

export default CheckboxInput;
