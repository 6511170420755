import styled, { css } from 'styled-components';
import Confetti from 'react-confetti';

export const Wrapper = styled.div`
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.section`
  ${({ theme }) => css`
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 2.5rem;

    > svg {
      width: 15rem;
      height: 15rem;
      color: ${theme.colors['green-900']};
      margin-bottom: 3rem;
    }

    a {
      color: ${theme.colors.white};
      display: block;
      margin-top: 2.5rem;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${theme.colors['green-900']};
      padding: 1rem;
      border-radius: 0.5rem;

      &:hover {
        filter: brightness(0.9);
      }

      > svg {
        margin-right: 1rem;
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  `}
`;

export const Title = styled.text`
  font-size: 3.2rem;
  color: ${({ theme }) => theme.colors['green-900']};
  text-align: center;
  `;

export const Description = styled.text`
color: ${({ theme }) => theme.colors['blue-shade-700']};
text-align: center;
`;

export const ConfettiComponent = styled(Confetti)`
  width: 100%;
  height: 100vh;
`;
