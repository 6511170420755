import React from 'react';
import { FiCheckCircle, FiLogIn } from 'react-icons/fi';

import { Wrapper, Container, Title, Description, ConfettiComponent } from './styles';

const Congrats: React.FC = () => {

  return (
    <Wrapper>
      <Container>
        <FiCheckCircle />
        <Title>Cadastro realizado com sucesso!</Title>
        <Description>Estamos felizes com a sua chegada.</Description>

        <a href="/login">
          <FiLogIn />
          Fazer login
        </a>
        <ConfettiComponent />
      </Container>
    </Wrapper>
  );
};

export default Congrats;
