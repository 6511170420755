import L from 'leaflet';

import mapPinImg from '../assets/locationIcon.svg';

const mapIcon = L.icon({
  iconUrl: mapPinImg,
  iconSize: [120, 120],
  iconAnchor: [29, 68],
  popupAnchor: [105, -20],
});

export default mapIcon;
