import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/AuthContext';
import getValidationErrors from '../../utils/getValidationsErrors';
import Header from '../../components/Header';
import Input from '../../components/Input';
import * as S from './styles';

interface FormData {
  old_password: string;
  password: string;
  password_confirmation: string;
}

const EditProfilePassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { updateCaregiver } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: FormData) => {
      const formData = {
        ...data,
      };

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          old_password: Yup.string(),
          password: Yup.string().when('old_password', {
            is: (val: string) => !!val.length,
            then: Yup.string()
              .min(6, 'Mínimo de 6 dígitos')
              .required('Campo obrigatório'),
            otherwise: Yup.string(),
          }),
          password_confirmation: Yup.string()
            .when('old_password', {
              is: (val: string) => !!val.length,
              then: Yup.string().required('Confirme a nova senha'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password'), null], 'As senhas não conferem'),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const token = localStorage.getItem('@Cuid:token');
        api.defaults.headers.authorization = `Bearer ${token}`;

        const response = await api.put('/perfil/senha', formData);

        updateCaregiver(response.data);

        history.push('/dashboard');

        addToast({
          type: 'success',
          title: 'Perfil atualizado!',
          description: 'Suas informações foram atualizadas!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro ao atualizar perfil, tente novamente.',
        });
      }
    },
    [addToast, history, updateCaregiver],
  );

  return (
    <S.Container>
      <Header path="/perfil" />

      <S.Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <fieldset>
            <legend>Informações de conta</legend>

            <S.Label htmlFor="old_password">Senha antiga</S.Label>
            <Input
              id="old_password"
              autoComplete="false"
              name="old_password"
              type="password"
            />

            <S.Label htmlFor="password">Nova senha</S.Label>
            <Input autoComplete="false" name="password" type="password" />

            <S.Label htmlFor="password">Confirmar nova senha</S.Label>
            <Input
              autoComplete="false"
              name="password_confirmation"
              type="password"
            />
          </fieldset>
          <S.UpdateButton type="submit">Salvar alterações</S.UpdateButton>
        </Form>
      </S.Content>
    </S.Container>
  );
};
export default EditProfilePassword;
