import React, { useState } from 'react';
import Header from '../../../components/Header';
import { Heart } from 'react-iconly';
import {
  FiShare,
  FiFlag,
  FiCheck,
  FiMapPin,
  FiArrowRight,
} from 'react-icons/fi';
import * as S from './styles';
import { Map, Marker, TileLayer } from 'react-leaflet';
import mapIconHouse from '../../../utils/mapIconHouse';

import img1 from '../../../images/image-1.png';
import img2 from '../../../images/image-2.png';
import img3 from '../../../images/image-3.png';
import img4 from '../../../images/image-4.png';
import img5 from '../../../images/image-5.png';

export default function PartnerProfile(): JSX.Element {
  const slug = window.location.pathname.replace('/empresas/', '');
  const layNameBusiness = slug.replaceAll('%20', ' ');
  const [nameBusiness] = useState(layNameBusiness);

  const [isActive, setIsActive] = useState(false);

  function handleFavoriteButton() {
    setIsActive(!isActive);
  }

  return (
    <>
      <Header path="/" />
      <S.Container>
        <S.Main>
          <S.Name>
            <span>
              <h1>{nameBusiness}</h1>
              <span>Av. Brg. Faria Lima, 3477 - Itaim Bibi - SP</span>
            </span>

            <div className="actions">
              <button>
                <FiShare size={20} />

                <span>Compartilhar</span>
              </button>
              <button
                onClick={handleFavoriteButton}
                className={`${isActive ? 'active' : ''}`}
              >
                <Heart size={20} />

                <span>Salvar</span>
              </button>
            </div>
          </S.Name>

          <S.Gallery>
            <div className="carousel">
              <div className="hero">
                <img src={img1} alt="casa 1" />
              </div>
              <div className="slider">
                <div>
                  <p>
                    Arraste <br />
                    para ver mais
                  </p>
                  <FiArrowRight size={20} />
                </div>
              </div>
              <div className="grid">
                <img src={img2} alt="casa 2" />
                <img src={img3} alt="casa 3" />
                <img src={img4} alt="casa 4" />
                <img src={img5} alt="casa 5" />
              </div>
            </div>
          </S.Gallery>

          <S.Content>
            <div className="details">
              <div className="details-title">
                <h2>
                  A comodidade que seu familiar precisa, você encontra aqui
                </h2>
                <div className="tags">
                  <span>enfermagem 24h</span>
                  {' • '}
                  <span>área de lazer</span>
                  {' • '}
                  <span>6 refeições diárias</span>
                  {' • '}
                  <span>lavanderia</span>
                </div>
              </div>

              <div className="divider"></div>

              <div className="divider"></div>

              <div className="details-resume">
                <p>
                  Somos um Residencial para Idosos que preza pelo cuidado e
                  bem-estar dos nossos moradores. Contamos com enfermeiras
                  presentes 24 horas por dia e uma equipe multidisciplinar
                  especializada para garantir que todos recebam, continuamente,
                  os cuidados necessários para a manutenção e melhora da saúde.
                </p>
                <br />
                <p>
                  Além disso, nossa estrutura é adaptada e equipada para atender
                  todas as necessidades dos nossos residentes, oferecendo um
                  ambiente seguro e acolhedor para uma vida saudável e mais
                  feliz.
                </p>
                {/* <a href="#">
                  continuar lendo
                </a> */}
              </div>

              <div className="divider"></div>

              <div className="details-title">
                <h2>O que oferecemos</h2>

                <div className="service-list">
                  <ul>
                    <li>
                      <FiCheck />
                      Enfermagem
                    </li>
                    <li>
                      <FiCheck />
                      Médico
                    </li>
                    <li>
                      <FiCheck />6 refeições diárias
                    </li>
                    <li>
                      <FiCheck />
                      Ambientes climatizados
                    </li>
                    <li>
                      <FiCheck />
                      Áreas de lazer
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <FiCheck />
                      Fisioteraupeuta
                    </li>
                    <li>
                      <FiCheck />
                      Fonoaudiologo
                    </li>
                    <li>
                      <FiCheck />
                      Nutricionista
                    </li>
                    <li>
                      <FiCheck />
                      Gerontologo
                    </li>
                  </ul>
                </div>
              </div>

              <div className="divider"></div>

              <div className="details-location">
                <h2>Onde estamos</h2>

                <div className="service-items">
                  <S.WrapperMap2>
                    <Map
                      className="mapa"
                      center={[-23.6467873, -46.6338955]}
                      zoom={16}
                      style={{
                        height: 430,
                        borderTopLeftRadius: '3.2rem',
                        borderTopRightRadius: '3.2rem',
                      }}
                      dragging={false}
                      touchZoom={false}
                      zoomControl={false}
                      scrollWheelZoom={false}
                      doubleClickZoom={false}
                    >
                      <TileLayer
                        url={`https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                      />

                      <Marker
                        interactive={false}
                        icon={mapIconHouse}
                        position={[-23.6467873, -46.6338955]}
                        zIndexOffset={20}
                      />
                    </Map>

                    <footer>
                      <FiMapPin />
                      <p>Itaim Bibi, São Paulo</p>
                    </footer>
                  </S.WrapperMap2>
                </div>
              </div>
            </div>

            <div className="contact">
              <form>
                Planos a partir de
                <span>
                  <strong>R$ 1.780</strong> / mês
                </span>
                <p>
                  Preencha os dados abaixo. Ao clicar em Enviar, você será
                  direcionado para o WhatsApp
                </p>
                <div className="container-picker">
                  <div>
                    <div className="picker">
                      <label htmlFor="checkin">Nome</label>
                      <input type="text" name="checkin" id="checkin" />
                    </div>
                    <div className="spacer"></div>
                    <div className="picker line-top">
                      <label htmlFor="checkout">Email</label>
                      <input type="email" name="checkout" id="checkout" />
                    </div>
                  </div>

                  <div className="picker line-top">
                    <label htmlFor="checkout">Mensagem</label>
                    <textarea
                      rows={3}
                      placeholder="Olá, quero mais detalhes. Por favor entre em contato."
                      name="checkout"
                      id="checkout"
                    />
                    {/* <label htmlFor="accommodation">Tipo de Hospedagem</label>
                    <select name="accommodation" id="accommodation">
                      <option value="">Por quanto tempo?</option>
                      <option value="dia">Diário</option>
                      <option value="mensal">Mensal</option>
                      <option value="personalizado">Personalizado</option>
                    </select> */}
                  </div>
                </div>
                <button>Contato via whatsapp</button>
              </form>
              <span>
                <FiFlag />
                Reportar esse perfil
              </span>
            </div>
          </S.Content>
        </S.Main>
      </S.Container>
    </>
  );
}
