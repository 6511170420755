import React from 'react';
import { Home, InfoSquare, User, Logout, Bag, Lock } from 'react-iconly';
import * as S from './styles';

import logo from '../../images/logo-cuid.png';
import { useAuth } from '../../hooks/AuthContext';
import { Link } from 'react-router-dom';

const Sidebar: React.FC = () => {
  const { signOut } = useAuth();

  return (
    <S.LSideBar>
      <img src={logo} className="logo" alt="Logo da plataforma Cuid" />
      <S.Menu>
        <div>
          <ul>
            <a href="/dashboard">
              <li className="active">
                <Home set="curved" stroke="bold" />
                Home
              </li>
            </a>
            {/* <a href="/planos">
              <li>
                <Wallet
                  set='curved'
                  stroke='bold'
                />
                Planos
              </li>
            </a>
            <a href="/dashboard">
              <li>
                <Document set="curved" stroke="bold" />
                Documentação
              </li>
            </a>*/}
            <Link to="/perfil/editar">
              <li>
                <User set="curved" stroke="bold" />
                Editar Perfil
              </li>
            </Link>

            <Link to="/perfil/editar/senha">
              <li>
                <Lock set="curved" stroke="bold" />
                Alterar Senha
              </li>
            </Link>
            {/**
 *             <a
              href="https://clube.cuid.com.br/entrar?redirectTo=%2F"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <Bag set="curved" stroke="bold" />
                Clube Cuid
              </li>
            </a>
 *
 */}
            {/* <li>
              <Document
                set='curved'
                stroke='bold'
              />
              Documentos
            </li>

            {/*
            <Link to="/parceiros">
              <li>
                <People
                  set='curved'
                  stroke='bold'
                />
                Parceiros
              </li>
            </Link>
*/}

            <a href="https://api.whatsapp.com/send?phone=5511959984119&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20meu%20cadastro%20na%20plataforma%20Cuid.">
              <li>
                <InfoSquare set="curved" stroke="bold" />
                Suporte
              </li>
            </a>
          </ul>
        </div>

        <div>
          <ul>
            <li id="Danger" onClick={signOut}>
              <Logout set="curved" stroke="bold" />
              Sair
            </li>
          </ul>
        </div>
      </S.Menu>
    </S.LSideBar>
  );
};

export default Sidebar;
