import styled, { css } from 'styled-components';

import Button from '../Button';

export const CloseButton = styled.button`
  ${({ theme }) => css`
      font-size: 0;
      border: none;
      background: transparent;

      position: absolute;
      top: 2rem;
      right: 2rem;

      svg {
        width: 2rem;
        height: 2rem;
        color: ${theme.colors['blue-shade-400']};
      }
  `}
`;

export const Container = styled.div`

  .input-box {
    border: 1px solid #d3e2e5;
  }

  ${({ theme }) => css`
    padding: 4rem 5.6rem;
    border-radius: 1.2rem;
    background: ${theme.colors.white};
    position: relative;

    form {
      display: flex;
      flex-direction: column;
    }

    select,
    textarea,
    input + input {
      margin-top: 2rem;
    }

    select {
      option.option-placeholder {
        color: ${theme.colors['shade-300']}
      }
    }

    select,
    textarea,
    input {
      width: 100%;
      padding: 0 1.6rem;
      height: 5.6rem;
      font-family: 'Poppins', sans-serif;
      font-size: 1.8rem;
      color: #1c3438;
      background: rgb(248, 248, 252);
      border-radius: 0.8rem;
      border: none;
      outline: 0;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }

    textarea {
      height: 10rem;
      resize: none;
      padding: 1.6rem;
    }
  `}
`;
export const SubmitButton = styled(Button)`
  max-width: 30rem;
  margin-top: 2rem;
`;

export const CheckBoxWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 2rem;
    display: flex;
    align-items: center;
    position: relative;

    input {
      -webkit-appearance: none;
      width: 3rem;
      height: 3rem;
      border: 1px solid ${theme.colors['blue-50']};
      border-radius: 1rem;
      vertical-align: sub;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      outline: none;

      &:checked {
        background-color: ${theme.colors['green-600']};
        border-color: ${theme.colors['green-600']};

        & + label {
          color: ${theme.colors['blue-shade-200']};
          font-weight: 600;
          background-color: hsl(0, 0%, 97%);
        }

        &:focus,
        &:hover {
          box-shadow: 0 0 0 3px ${theme.colors['green-600']};
          border-color: ${theme.colors['green-600']};
        }
      }

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==");
        background-size: 40px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:focus,
      &:hover {
        box-shadow: 0 0 0 3px rgba(60, 220, 140, 0.2);
        border-color: ${theme.colors['green-600']};
      }
    }

    label {
      margin-left: 4rem;
      color: ${theme.colors['blue-shade-400']};
    }
  `}

  #permissionContact {
    input[type="checkbox"] {
      width: 25px;
      border: 1px solid #37c77f;
    }
}
`;


export const WrapperToggleButton = styled.div`
  width: 100%;
  display: flex;

  button {
    width: 100%;
    height: 5rem;
    background: #f5f8fa;
    border: 6.2rem auto;
    color: #1c3438;
    cursor: pointer;

    &.active {
      background: #edfff6;
      border: 1px solid #a1e9c5;
      color: #37c77f;
    }

    &:first-child {
      border-radius: 0.5rem 0 0 0.5rem;
    }

    &:last-child {
      border-radius: 0 0.5rem 0.5rem 0;
      border-left: 0;
    }
  }
`;
