import React from 'react';
import { ThemeProvider } from 'styled-components';
import Modal from 'react-modal';

import GlobalStyle from './styles/global';
import { theme } from './styles/theme';

import Routes from './Routes';

Modal.setAppElement('#root');

function App(): JSX.Element {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes />
        <GlobalStyle />
      </ThemeProvider>
    </>
  );
}

export default App;
