import styled from 'styled-components';

export const Container = styled.div`
  margin: 32rem 0;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 2px dashed #ddd;
  border-radius: 8px;
  text-align: center;

  svg {
    height: 80px;
    width: 80px;
    margin-bottom: 2rem;
    padding: 2rem;
    background: #ddd;
    border-radius: 50%;
    color: #0118;
  }
`;
