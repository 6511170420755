import { createGlobalStyle, css } from 'styled-components';

import 'leaflet/dist/leaflet.css';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    -webkit-font-smoothing: antialiased;
  }
  html {
    font-size: 62.5%; /* 1rem to equal 10px */
  }
  @media (max-width: 1080px) {
    html {
      font-size: 56.25%; /* 0.9rem to equal 9px */
    }
  }
  @media (max-width: 1024px) and (height: 1366px) {
    html {
      font-size: 62.5%; /* 1rem to equal 10px */
    }
  }
  @media (max-width: 720px) {
    html {
      font-size: 50%; /* 0.8rem to equal 8px */
    }
  }
  body {
    ${({ theme }) => css`
      color: ${theme.colors.white};
      background: ${theme.colors['shade-100']};
    `}
  }
  /* comitando */
  body,
  input,
  button,
  textarea,
  select {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 1.6rem;
  }
  button {
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: inherit;
  }

  .leaflet-container {
    z-index: 1;
  }

  /* .leaflet-pane .leaflet-marker-pane img {
    z-index: 0;
  } */

  .map-popup .leaflet-popup-content-wrapper {
    width: 30rem;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  .map-popup .leaflet-popup-content {
    padding: 0;
    margin: 0;
    width: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  .map-popup .leaflet-popup-tip-container {
    display: none;
  }
  .leaflet-container a.leaflet-popup-close-button {
    padding-top: 1rem;
    padding-right: 2.5rem;
    font-size: 3rem;
  }

  .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    display: none;
}

  .leaflet-popup-content-wrapper, .leaflet-popup-tip {
    margin: 0;
    padding: 0;
  }

  .leaflet-marker-icon {
    z-index: 1 !important;
  }

  /* .modal--contact-report {
    z-index: 999999;
  } */
`;
