import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { FiX } from 'react-icons/fi';

import { useSocialShare } from '../../hooks/SocialShareContext';

import * as S from './styles';
import CopyToClipboard from '../../utils/CopyAndPaste/CopyToClipboard';

type SocialShareProps = {
  title?: string;
};

export function SocialShare({ title }: SocialShareProps): JSX.Element {
  const { shareURL, handleCloseSocialShare } = useSocialShare();

  return (
    <S.Container>
      <S.WrapperContent>
        <S.Content>
          <div>
            <strong>Compartilhar:</strong>

            <button type="button" onClick={handleCloseSocialShare}>
              <FiX />
            </button>
          </div>

          <section>
            <div>
              <WhatsappShareButton url={shareURL} title={title} separator=": ">
                <WhatsappIcon round />
              </WhatsappShareButton>

              <span>WhatsApp</span>
            </div>

            <div>
              <TelegramShareButton url={shareURL} title={title}>
                <TelegramIcon round />
              </TelegramShareButton>

              <span>Telegram</span>
            </div>

            <div>
              <FacebookShareButton url={shareURL} quote={title}>
                <FacebookIcon round />
              </FacebookShareButton>

              <span>Facebook</span>
            </div>

            <div>
              <TwitterShareButton url={shareURL} title={title}>
                <TwitterIcon round />
              </TwitterShareButton>

              <span>Twitter</span>
            </div>

            <div>
              <LinkedinShareButton url={shareURL}>
                <LinkedinIcon round />
              </LinkedinShareButton>

              <span>Linkedin</span>
            </div>

            <div>
              <EmailShareButton url={shareURL} subject={title} body={title}>
                <EmailIcon round />
              </EmailShareButton>

              <span>Email</span>
            </div>
          </section>

          <footer>
            <CopyToClipboard>
              {({ copy }) => (
                <button type="button" onClick={() => copy(shareURL)}>
                  <span>{shareURL}</span>
                  Copiar
                </button>
              )}
            </CopyToClipboard>
          </footer>
        </S.Content>
      </S.WrapperContent>
    </S.Container>
  );
}
