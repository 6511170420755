import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    white: '#FFFFFF',

    'shade-100': '#efefef',
    'shade-150': '#f8f8fc',
    'shade-300': '#777',
    'shade-900': '#fff',

    'green-600': '#04d361',
    'green-900': '#1c3438',
    'green-910': '#13b35e',

    'blue-50': '#d3e2e5',
    'blue-light-100': '#b3dae2',
    'blue-light-120': '#b3dae29d',
    'blue-light-900': '#0089a5',

    'blue-shade-200': '#8fa7b3',
    'blue-shade-300': '#1c3438',
    'blue-shade-400': '#1c3438',
    'blue-shade-700': '#3C4858',

    'blue-dark-500': '#2d294e',

    'red-800': '#F03442',
  },
};

export { theme };
