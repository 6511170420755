import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { AuthProvider } from '../hooks/AuthContext';
import { SocialShareProvider } from '../hooks/SocialShareContext';
import Route from './route';
import FindCaregiver from '../pages/FindCaregiver2';
import CaregiverShare from '../pages/CaregiverShare';
import RegisterUser from '../pages/RegisterUser';
import Congrats from '../pages/Congrats';
import SignIn from '../pages/SignIn';
import Profile from '../pages/Profile';
import Dashboard from '../pages/V2/Dashboard';
import EditProfile from '../pages/EditProfile';
import EditProfilePassword from '../pages/EditProfilePassword';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Success from '../pages/Payments';
import PartnerProfile from '../pages/V2/Partners';
import DashboardPartners from '../pages/V2/DashboardPartners';

import RegisterUserModal from '../components/RegisterUserModal'; // to register leads

import RegisterUserABC from '../pages/RegisterUserABC'; // to partership with abc cuidadores

import PaymentMonthly from '../pages/Payments/Monthly';
import SearchProfessionals from '../pages/V2/SearchProfessionals/SearchProfessionals';

function Routes(): JSX.Element {
  return (
    <BrowserRouter>
      <Switch>
        <AuthProvider>
          <SocialShareProvider>
            <Route path="/" exact component={SearchProfessionals} />
            <Route path="/busca/:city" component={FindCaregiver} />

            <Route path="/profissionais/cadastro" component={RegisterUser} />
            <Route
              path="/abc-cuidadores/cadastro"
              component={RegisterUserABC}
            />
            <Route path="/me/:slug" component={CaregiverShare} />
            <Route path="/cadastro-realizado/" component={Congrats} />
            <Route path="/recuperar-senha/" component={ForgotPassword} />
            <Route path="/resetar-senha/" component={ResetPassword} />
            <Route path="/login/" component={SignIn} />

            <Route path="/dashboard/" exact component={Dashboard} isPrivate />

            <Route path="/perfil/" exact component={Profile} isPrivate />
            <Route
              path="/perfil/editar"
              exact
              component={EditProfile}
              isPrivate
            />
            <Route
              path="/perfil/editar/senha"
              component={EditProfilePassword}
              exact
              isPrivate
            />
            <Route path="/perfil/:slug" component={CaregiverShare} isPrivate />

            <Route path="/parceiros" component={DashboardPartners} isPrivate />

            <Route path="/planos/" exact component={PaymentMonthly} isPrivate />

            <Route path="/empresas/:slug" exact component={PartnerProfile} />

            <Route
              path="/cadastro/contratante"
              exact
              component={RegisterUserModal}
            />

            <Route
              path="/payments/create-checkout-session"
              component={PaymentMonthly}
              isPrivate
            />

            <Route path="/assinatura-concluida" component={Success} isPrivate />
          </SocialShareProvider>
        </AuthProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
