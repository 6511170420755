import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  ${({ theme }) => css`
    background: ${theme.colors['green-600']};
    height: 5.6rem;
    border-radius: 1rem;
    border: 0;
    padding: 0 16px;
    color: ${theme.colors.white};
    width: 100%;
    font-weight: bold;
    /* margin-top: 16px; */
    transition: background-color 0.2s;
    &:hover {
      background: ${shade(0.2, theme.colors['green-600'])};
      cursor: pointer;
    }
  `}
`;
