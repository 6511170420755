import React, { useEffect, useState } from 'react';

import { FaWhatsapp } from 'react-icons/fa';
import {
  FiCalendar,
  FiInfo,
  FiMapPin,
  FiShield,
  FiMail,
  FiShare2,
  FiFlag,
} from 'react-icons/fi';
import { Map, Marker, TileLayer } from 'react-leaflet';
import { useParams } from 'react-router-dom';

import api from '../../services/api';

import mapIcon from '../../utils/mapIcon';
import { formatCurrency } from '../../utils/mask';

import Header from '../../components/Header';
import ContactModal from '../../components/ContactModal';

import * as S from './styles';
import { useSocialShare } from '../../hooks/SocialShareContext';
import { getFirstAndMiddleName } from '../../utils/formatString';
interface Caregiver {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  occupation: string;
  cost: number;
  avatar_url: string;
  bio: string;
  neighborhood: string;
  city: string;
  uf: string;
  formation: string;
  availability: string;
  whatsapp: string;
  email: string;
  genre: string;
  slug: string;
}
interface CaregiverParams {
  slug: string;
}

export default function CaregiverShare(): JSX.Element {
  const params = useParams<CaregiverParams>();
  const [caregiver, setCaregiver] = useState<Caregiver>();
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const { toggleSocialShare } = useSocialShare();

  useEffect(() => {
    api.get(`/cadastro/profissionais/${params.slug}`).then(res => {
      setCaregiver(res.data.caregiver);
    });
  }, [params.slug]);

  if (!caregiver) {
    return <p>Carregando...</p>;
  }

  fetch('https://ntfy.sh/202211_NOTIFICATIONS_ABOUT_CUID', {
    method: 'POST',
    body: `Estão visualizando o perfil de ${caregiver.name} no link: ${window.location.href}`,
    headers: {
      Priority: '2',
      Tags: 'loudspeaker',
    },
  });

  function sendMessageOnWhatsapp() {
    fetch('https://ntfy.sh/202211_NOTIFICATIONS_ABOUT_CUID', {
      method: 'POST',
      body: `Solicitação de contato por Whatsapp no perfil de ${caregiver?.name} no link: ${window.location.href}`,
      headers: {
        Priority: '2',
        Tags: 'loudspeaker',
      },
    });
  }

  function sendMessageOnEmail() {
    fetch('https://ntfy.sh/202211_NOTIFICATIONS_ABOUT_CUID', {
      method: 'POST',
      body: `Solicitação de contato por Email no perfil de ${caregiver?.name} no link: ${window.location.href}`,
      headers: {
        Priority: '2',
        Tags: 'loudspeaker',
      },
    });
  }

  const costFormatted = formatCurrency(caregiver.cost);
  const formattedName = getFirstAndMiddleName(caregiver.name);
  const slug = caregiver.slug;
  return (
    <>
      <meta
        name="description"
        content="Veja esse profissional que eu encontrei na Cuid"
      />

      <Header path="/" />

      <S.Container>
        <S.Content>
          <S.WrapperShare>
            <button
              type="button"
              onClick={() =>
                toggleSocialShare(
                  `https://app.cuid.com.br/me/${slug}`,
                  'Veja esse profissional que encontrei na Cuid',
                )
              }
            >
              <FiShare2 />
              Compartilhe este perfil
            </button>
          </S.WrapperShare>

          <S.Avatar>
            {caregiver.avatar_url ? (
              <img src={caregiver.avatar_url} alt={formattedName} />
            ) : (
              <img
                src="https://app-cuid.s3.amazonaws.com/no-avatar.png"
                alt="profissional sem foto de perfil"
              />
            )}
          </S.Avatar>

          <S.DetailsContainer>
            <h1 style={{ textTransform: 'capitalize' }}>{formattedName}</h1>
            <p>
              {caregiver.genre === 'Feminino' &&
              caregiver.occupation === 'Cuidador de Idosos'
                ? 'Cuidadora de Idosos'
                : caregiver.occupation}
            </p>
            <p>
              {costFormatted}
              /Plantão
            </p>

            <h2>Resumo</h2>
            <p>{caregiver.bio}</p>

            <S.ContactButtons>
              <button type="button" onClick={sendMessageOnWhatsapp}>
                <FaWhatsapp />
                <a
                  href={`https://wa.me/5511959984119?text=Ol%C3%A1!%0A%0AGostaria%20de%20mais%20informa%C3%A7%C3%B5es%20referente%20ao%20profissional%20abaixo:%0A%0ANome%20do%20Profissional: ${
                  caregiver.name.split(' ')[0]}%0ACargo: ${caregiver.occupation}%0ACidade: ${caregiver.city}%0AUF: ${caregiver.uf}%0ALink do perfil: http://app.cuid.com.br/me/${caregiver.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contato via Whatsapp
                </a>
              </button>

              <button type="button">
                <FiMail />
                <a
                  href={`mailto:oi@cuid.com.br?subject=CUID%20-%20Novo%20plantão!%20Você%20está%20disponivel?%20&body=Ol%C3%A1!%0A%0AGostaria%20de%20mais%20informa%C3%A7%C3%B5es%20referente%20ao%20profissional%20abaixo:%20Profissional: ${
                  caregiver.name.split(' ')[0]}%0ACargo: ${caregiver.occupation}%0ACidade: ${caregiver.city}%0AUF: ${caregiver.uf}%0ALink do perfil: http://app.cuid.com.br/me/${caregiver.slug}`}
                  onClick={sendMessageOnEmail}
                >
                  Contato via Email
                </a>
              </button>
            </S.ContactButtons>

            <S.WrapperMap>
              {!isContactModalOpen && (
                <Map
                  center={[caregiver.latitude, caregiver.longitude]}
                  zoom={16}
                  style={{
                    width: '100%',
                    height: 280,
                    borderTopLeftRadius: '3.2rem',
                    borderTopRightRadius: '3.2rem',
                  }}
                  dragging={false}
                  touchZoom={false}
                  zoomControl={false}
                  scrollWheelZoom={false}
                  doubleClickZoom={false}
                >
                  <TileLayer
                    url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                  />

                  <Marker
                    interactive={false}
                    icon={mapIcon}
                    position={[caregiver.latitude, caregiver.longitude]}
                    zIndexOffset={3}
                  />
                </Map>
              )}

              <footer>
                <FiMapPin />
                {`${caregiver.neighborhood}, ${caregiver.city}`}
              </footer>
            </S.WrapperMap>

            <S.WrapperCards>
              <div>
                <FiCalendar />
                Disponibilidade <br />
                para {caregiver.availability}
              </div>

              {caregiver.formation !== '' ? (
                <div>
                  <FiShield />
                  {caregiver.formation === 'true' || 'Profissional certificado'
                    ? 'Profissional certificado'
                    : 'Não possui certificação'}
                </div>
              ) : (
                <div className="dont-open">
                  <FiInfo />
                  Profissional <br />
                  não possui certificação
                </div>
              )}
            </S.WrapperCards>
          </S.DetailsContainer>
        </S.Content>

        <S.ContactButton
          type="button"
          onClick={() => setIsContactModalOpen(true)}
        >
          <FiFlag />
          Denunciar este perfil
        </S.ContactButton>
      </S.Container>

      <ContactModal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      />
    </>
  );
}
