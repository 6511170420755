import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { FiCamera, FiLink, FiEdit3 } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import axios from 'axios';
import slugify from 'slugify';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/AuthContext';
import getValidationErrors from '../../utils/getValidationsErrors';
import { formatCurrency, cpfMask, phoneMask, cepMask } from '../../utils/mask';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Select from '../../components/Select';
import { TextArea } from '../../components/TextareaComponent';

import imgNoProfile from '../../images/no-avatar.png';
import * as S from './styles';

const defaultMessage = 'Campo obrigatório';
const alerMessage = 'número máximo de caracteres excedido!';
const options = [
  { value: 'true', label: 'Sim' },
  { value: 'false', label: 'Não' },
];

interface FormData {
  name: string;
  slug: string; //
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
  whatsapp: string; //
  cpf: string;
  zip: string;
  address: string;
  numberHouse: string;
  city: string;
  uf: string;
  neighborhood: string;
  latitude: number;
  longitude: number;
  birthday: Date;
  bio: string;
  occupation: string;
  cost: number;
  availability: string;
  formation: string;
  genre: string;
}
interface CaregiverLocation {
  responseObject: {
    zipcode: string;
    longitude: number | string;
    latitude: number | string;
    venue: string;
    neighborhood: string;
    city: string;
    state: string;
  };
}

const EditProfile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { user, updateCaregiver } = useAuth();
  const { addToast } = useToast();

  const zipFormatted = cepMask(user.zip);

  const [zip, setZip] = useState(zipFormatted); // user.zip
  // eslint-disable-next-line
  const [address, setAddress] = useState<string>('');
  const [neighborhood, setNeighborhood] = useState('');
  const [availability] = useState('');
  // const [formation] = useState(true);
  const [city, setCity] = useState('');
  const [uf, setUf] = useState(''); //
  const [longitude, setLongitude] = useState<number | string>(user.longitude);
  const [latitude, setLatitude] = useState<number | string>(user.latitude);
  const [slug, setSlug] = useState(user.slug);

  const [formation, setFormation] = useState({
    value: {
      label: user.formation === 'true' ? 'Sim' : 'Não',
      value: user.formation === 'true' ? 'true' : 'false',
    },
  });

  // const formationFormatted = ;
  const costFormatted = formatCurrency(user.cost);
  const cpfFormatted = cpfMask(user.cpf);
  const whatsappFormatted = phoneMask(user.whatsapp);

  const [costValue, setCostValue] = useState(costFormatted);

  // handle format date dd-MM-yyyy
  const [year, month, day] = user.birthday.split('T')[0].split('-');
  const dateFormatted = `${day}/${month}/${year}`;

  const initialFormaData = {
    name: user.name,
    slug: user.slug,
    email: user.email,
    whatsapp: whatsappFormatted,
    cpf: cpfFormatted,
    zip: zipFormatted,
    address: user.address,
    numberHouse: user.numberHouse,
    city: user.city,
    uf: user.uf,
    neighborhood: user.neighborhood,
    latitude: user.latitude,
    longitude: user.longitude,
    birthday: dateFormatted,
    bio: user.bio,
    occupation: user.occupation,
    cost: costFormatted,
    availability: user.availability,
    formation: user.formation === 'true' ? 'Sim' : 'Não',
    genre: user.genre,
  };

  const loadLocationData = useCallback(() => {
    axios
      .get<CaregiverLocation>(
        `https://api.famyle.com/api/locations/zipcode/${zip}`,
      )
      .then(response => {
        const { venue, neighborhood, city, state, latitude, longitude } =
          response.data.responseObject;
        setAddress(venue);
        setCity(city);
        setNeighborhood(neighborhood);
        setUf(state);
        setLongitude(longitude);
        setLatitude(latitude);
      });
  }, [zip]);

  useEffect(() => {
    loadLocationData();
  }, [zip, loadLocationData]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      // handle format date to yyyy-MM-ddT00:00:00.000Z
      const [day, month, year] = String(data.birthday).split('/');
      const fullDate = `${year}-${month}-${day}T00:00:00.000Z`;

      const formattedFormData = {
        ...data,

        cpf: data.cpf.replace(/\D/gi, ''),
        whatsapp: data.whatsapp.replace(/\D/gi, ''),
        zip: data.zip.replace(/\D/gi, ''),
        birthday: fullDate,
        cost: data.cost.toString().replace(/\D/gi, ''),
        latitude: String(latitude),
        longitude: String(longitude),
        slug: slugify(data.slug, {
          replacement: '',
          remove: /[*+~.()'"!:@-]/g,
        }),
      };

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          slug: Yup.string().required('Informe um nome ou apelido'),
          email: Yup.string()
            .email('Digite um email válido')
            .required('E-mail obrigatório'),
          whatsapp: Yup.string().required(defaultMessage),
          cpf: Yup.string()
            .max(11, 'No máximo 11 digitos!')
            .required(defaultMessage),
          zip: Yup.string().max(8, alerMessage).required(defaultMessage),
          address: Yup.string().max(100, alerMessage).required(defaultMessage),
          numberHouse: Yup.string()
            .max(10, alerMessage)
            .required(defaultMessage),
          city: Yup.string().max(50, alerMessage).required(defaultMessage),
          uf: Yup.string().max(2, alerMessage).required(defaultMessage),
          neighborhood: Yup.string().required(defaultMessage),
          birthday: Yup.date().required(defaultMessage),
          bio: Yup.string().required(defaultMessage),
          occupation: Yup.string().required(defaultMessage),
          cost: Yup.string().required(defaultMessage),
          availability: Yup.string().required(defaultMessage),
          formation: Yup.string().required(defaultMessage),
          genre: Yup.string().required(defaultMessage),
        });

        await schema.validate(formattedFormData, {
          abortEarly: false,
        });

        const token = localStorage.getItem('@Cuid:token');
        api.defaults.headers.authorization = `Bearer ${token}`;

        const response = await api.put('/perfil', formattedFormData);

        updateCaregiver(response.data);

        history.push('/dashboard');

        addToast({
          type: 'success',
          title: 'Perfil atualizado!',
          description: 'Suas informações do perfil foram atualizadas!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro ao atualizar perfil, tente novamente.',
        });
      }
    },
    [addToast, latitude, longitude, history, updateCaregiver],
  );

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const token = localStorage.getItem('@Cuid:token');
      api.defaults.headers.authorization = `Bearer ${token}`;

      if (e.target.files) {
        const data = new FormData();

        data.append('avatar', e.target.files[0]);

        api.patch('/cadastro/avatar', data).then(response => {
          updateCaregiver(response.data);

          addToast({
            type: 'success',
            title: 'Foto de perfil atualizada!',
          });
        });
      }
    },
    [addToast, updateCaregiver],
  );

  function handleChange(value: any) {
    setFormation({ value });
  }

  return (
    <S.Container>
      <Header path="/perfil" />

      <S.Content>
        <Form
          ref={formRef}
          initialData={initialFormaData}
          onSubmit={handleSubmit}
        >
          <fieldset>
            <legend>Meu Perfil</legend>

            <S.Avatar>
              <div>
                {user.avatar_url ? (
                  <img src={user.avatar_url} alt={user.name} />
                ) : (
                  <img src={imgNoProfile} alt="usuário sem foto" />
                )}
                <label htmlFor="avatar">
                  <FiCamera />
                  <input
                    type="file"
                    id="avatar"
                    multiple
                    accept=".png, .jpg, .jpeg"
                    onChange={handleAvatarChange}
                  />
                </label>
              </div>
            </S.Avatar>

            <S.WrapperSlugEdit>
              <strong>
                <FiLink />
                Alterar sua URL Personalizada
              </strong>

              <S.Information title="Crie o seu nome de usuário exclusivo e compartilhe! 😊">
                <S.personalizeLink>
                  https://app.cuid.com.br/me/
                  <wbr />
                  <span>
                    <wbr />

                    {slugify(slug, {
                      replacement: '',
                      remove: /[*+~.()'"!:@-]/g,
                    })}
                  </span>
                  <FiEdit3 />
                </S.personalizeLink>
              </S.Information>

              <S.Label htmlFor="slug">Digite seu link personalizado</S.Label>
              <Input
                id="slug"
                name="slug"
                value={slug}
                onChange={e => setSlug(e.target.value)}
                maxLength={50}
              />
            </S.WrapperSlugEdit>

            <S.Label htmlFor="name">Nome completo</S.Label>
            <Input name="name" id="name" />

            <S.Label htmlFor="genre">Gênero </S.Label>
            <Input name="genre" id="genre" />

            <S.Label htmlFor="cpf">
              CPF <span>use somente números</span>
            </S.Label>
            <Input name="cpf" disabled />

            <S.Label htmlFor="whatsapp">Whatsapp</S.Label>
            <Input id="whatsapp" name="whatsapp" maxLength={12} />

            <S.Label htmlFor="zip">CEP</S.Label>
            <Input
              id="zip"
              name="zip"
              mask="cep"
              value={zip}
              onChange={event => setZip(event.target.value)}
              maxLength={9}
            />

            <S.Label htmlFor="address">Endereço</S.Label>
            <Input id="address" name="address" />

            <S.Label htmlFor="numberHouse">Número</S.Label>
            <Input id="numberHouse" name="numberHouse" maxLength={10} />

            <S.Label htmlFor="neighborhood">Bairro</S.Label>
            <Input id="neighborhood" name="neighborhood" />

            <S.Label htmlFor="city">Cidade</S.Label>
            <Input id="city" name="city" />

            <S.Label htmlFor="uf">Estado</S.Label>
            <Input id="uf" name="uf" />

            <S.Label htmlFor="birthday">Data de nascimento</S.Label>
            <Input id="birthday" name="birthday" />

            <S.Label htmlFor="bio">
              Sobre mim <span>Máximo de 300 caracteres</span>
            </S.Label>
            <TextArea id="bio" name="bio" maxLength={300} />
          </fieldset>

          <fieldset>
            <legend>Profissional</legend>

            <S.Label htmlFor="occupation">Área de atuação</S.Label>
            <Input id="occupation" name="occupation" />

            <S.Label htmlFor="cost">
              Valor do seu plantão <span>Considere um plantão de 12h</span>
            </S.Label>
            <Input
              id="cost"
              name="cost"
              value={formatCurrency(costValue)}
              onChange={e => setCostValue(e.target.value)}
            />

            <S.Label htmlFor="formation">
              Você tem formação em cuidados de pessoas?
            </S.Label>
            <Select
              id="formation"
              name="formation"
              value={formation.value}
              onChange={value => handleChange(value)}
              options={options}
            />

            <S.Label htmlFor="availability">
              Qual sua disponibilidade de trabalho
            </S.Label>
            <Input
              id="availability"
              name="availability"
              value={availability ? 'Mensal' : 'Diário'}
              disabled
            />
          </fieldset>

          <fieldset>
            <legend>Informações de conta</legend>

            <S.Label htmlFor="email">Email</S.Label>
            <Input id="email" name="email" type="email" />
          </fieldset>
          <S.UpdateButton type="submit">Salvar alterações</S.UpdateButton>
        </Form>
      </S.Content>
    </S.Container>
  );
};
export default EditProfile;
