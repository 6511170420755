import styled, { keyframes } from 'styled-components';
import signInBackground from '../../images/background.png';

import { shade } from 'polished';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  place-content: center;
  width: 100%;
  /* max-width: 700px; */
`;

const apperFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${apperFromLeft} 1s;
  img {
    width: 350px;
    height: 180px;
  }
  form {
    margin: 20px 0;
    width: 340px;
    text-align: center;
    input {
        border: .1rem solid rgb(231, 229, 229);
      }
    h1 {
      margin-bottom: 24px;
      color: #3C4858;
    }
    a {
      color: #3C4858;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.3, '#3C4858')};
      }
    }
  }
  > a {
    color: #04D361;
    display: block;
    margin-top: 16px;
    text-decoration: none;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
    &:hover {
      color: ${shade(0.2, '#04D361')};
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackground}) no-repeat center;
  background-size: cover;
`;
