import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
  FormEvent,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

import { cepMask, phoneMask } from './masks';

import * as S from './styles';

type CepMessage = {
  key: 'valid' | 'invalid' | '';
  message: string;
};

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  placeholder?: string;
  mask?: 'cep' | 'phone' | 'cpf';
  cepValidate?: CepMessage;
  cpfErrorMessage?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const Input: React.FC<InputProps> = ({
  label,
  name,
  placeholder,
  mask,
  cepValidate,
  cpfErrorMessage,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleKeyUp = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      if (mask === 'cep') {
        cepMask(event);
        return;
      }

      if (mask === 'phone') {
        phoneMask(event);
      }
    },
    [mask],
  );

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <S.Container
      isErrored={!!error || cepValidate?.key === 'invalid'}
      isFocused={isFocused}
      isFilled={isFilled}
      isValid={cepValidate?.key === 'valid'}
    >
      {/* {label && <label htmlFor={name}>{label}</label>} */}
      <input
        {...rest}
        id={name}
        ref={inputRef}
        defaultValue={defaultValue}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder={placeholder}
        onKeyUp={mask && handleKeyUp}
      />
      {!!error
        && (!!cepValidate?.message && cepValidate?.message.length) < 1 && (
          <span>{error}</span>
      )}

      {!!cepValidate && cepValidate.message.length > 1 && (
        <span>{cepValidate.message}</span>
      )}

      {cpfErrorMessage && <span>{cpfErrorMessage}</span>}
    </S.Container>
  );
};

export default Input;
