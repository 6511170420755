import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { loadStripe } from '@stripe/stripe-js';
import { FaWhatsapp } from 'react-icons/fa';
import {
  FiLogOut,
  FiShare2,
  FiInfo,
  FiUploadCloud,
  FiCheckCircle,
} from 'react-icons/fi';

import { useAuth } from '../../hooks/AuthContext';
import { mask, formatCurrency } from '../../utils/mask';
import api from '../../services/api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as S from './styles';

import logoImg from '../../images/logo-cuid.png';
import { getFirstAndMiddleName } from '../../utils/formatString';
import { useSocialShare } from '../../hooks/SocialShareContext';

const imgNoProfile = 'https://app-cuid.s3.amazonaws.com/no-avatar.png';

const Profile: React.FC = () => {
  const { signOut, user } = useAuth();
  const { toggleSocialShare } = useSocialShare();
  const dateFormatted = format(new Date(user.birthday), 'dd/MM/yyyy');
  const maskPhone = mask(`${user.whatsapp}`, '(##) #####-####');
  const maskCPF = mask(`${user.cpf}`, '###.###.###-##');
  const maskZIP = mask(`${user.zip}`, '#####-###');
  const maskCost = formatCurrency(`${user.cost}`);
  const stripePromise = loadStripe(
    'pk_live_51IcJg5FxYI3sgKUsiC2NoBcCJoeWz2bep1hQZ4ZGj0onr7F8gH8koJoBijSzWIry3k7VLiOByKnn7v426j47zqRT00qxUoWYF6',
  );
  const handleClick = async (_event: any) => {
    const stripe = await stripePromise;

    const response = await api.post('/create-checkout-session', {
      method: 'POST',
    });

    const session = await response.data;
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe?.redirectToCheckout({
      sessionId: session.id,
    });

    if (result?.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };
  const pixKey =
    '00020101021126360014br.gov.bcb.pix011445696763000157520400005303986540514.905802BR5915Cuid Tecnologia6009SAO PAULO62070503***6304AE2A';
  async function handleCopyPix() {
    setIsCopied(!isCopied);
  }
  const [isCopied, setIsCopied] = useState(false);
  const [userEmailName, userEmailDomain] = user.email.split('@');
  const nameFormatted = getFirstAndMiddleName(user.name);

  function sendNotificationAboutDocs() {
    fetch('https://ntfy.sh/202211_NOTIFICATIONS_ABOUT_CUID', {
      method: 'POST',
      body: `Envio de documentos do usuário ${user.email}`,
      headers: {
        Priority: '2',
        Tags: 'loudspeaker',
      },
    });
  }

  return (
    <S.Container>
      <S.Header>
        <S.HeaderContent>
          <img src={logoImg} alt="Logo da plataforma Cuid" />

          <S.Profile>
            {user.avatar_url ? (
              <img src={user.avatar_url} alt={user.name} />
            ) : (
              <img src={imgNoProfile} alt={user.name} />
            )}

            <div>
              <span>Olá,</span>

              <Link to="/perfil/editar">
                <strong>{nameFormatted}</strong>
                <span>Editar perfil</span>
              </Link>
            </div>
          </S.Profile>

          <S.SignOutButton type="button" onClick={signOut}>
            <FiLogOut />
            Sair
          </S.SignOutButton>
        </S.HeaderContent>
      </S.Header>

      <S.Content>
        {user.verified ? (
          <S.VerifiedDocs>
            <div className="docsVerified verified">
              <FiCheckCircle />
              <p>Sua conta foi verificada com sucesso.</p>
            </div>
          </S.VerifiedDocs>
        ) : (
          <S.VerifiedDocs>
            <div className="docsVerified non-verified">
              <FiInfo />
              <span>
                Precisamos validar suas informações, para isso tenha em mãos seu
                documento de identificação com foto.
              </span>
            </div>

            <S.ContactButton type="button" onClick={sendNotificationAboutDocs}>
              <FiUploadCloud />
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdZAJlz86P_NEcDF4ZBfryCkR4rsE-mDA3HwsXCL8ddl3o32Q/viewform?usp=sf_link"
                rel="noopener noreferrer"
                target="_blank"
              >
                Enviar documentos
              </a>
            </S.ContactButton>
          </S.VerifiedDocs>
        )}

        {user.verified && !user.payment_status ? (
          <S.SectionBanner>
            <p>
              Assine o plano mensal por R$ 14,90 e seja encontrado por familias
              em qualquer lugar do Brasil, por menos de R$ 0,50 por dia.
            </p>
            <S.PaymentButton type="button" role="link" onClick={handleClick}>
              Pagar com cartão de crédito
            </S.PaymentButton>
            <p>ou</p>
            <S.PaymentButton type="button" role="link" onClick={handleCopyPix}>
              <CopyToClipboard
                text={pixKey}
                onCopy={() =>
                  alert('Chave pix copiada para a área de transferência')
                }
              >
                <p>{isCopied ? 'Chave pix copiada!' : 'Pagar com pix'}</p>
              </CopyToClipboard>
            </S.PaymentButton>

            <p>
              {isCopied
                ? 'No aplicativo do seu banco, ao pagar use a opção "Copia e Cola".'
                : ''}
            </p>
          </S.SectionBanner>
        ) : (
          ''
        )}

        {user.payment_status ? (
          <S.VerifiedDocs>
            <div className="docsVerified verified">
              <FiCheckCircle />
              <p>Sua assinatura premium foi ativada com sucesso.</p>
            </div>
          </S.VerifiedDocs>
        ) : (
          ''
        )}

        {/* {user.payment_status ? (
          <S.SectionBanner>
            <p>
              <span role="img" aria-label="Party Popper">🎉</span>
              Sua assinatura premium foi ativada com sucesso!</p>
          </S.SectionBanner>

        ) : (
          <S.SectionBanner>
            <p>
              Assine o plano mensal por R$ 14,90 e seja encontrado por familias em qualquer
              lugar do Brasil, por menos de R$ 0,50 por dia.
            </p>
            <S.PaymentButton type="button" role="link" onClick={handleClick}>
              Pagar com cartão de crédito
            </S.PaymentButton>
            <p>ou</p>
            <S.PaymentButton type="button" role="link" onClick={handleCopyPix}>
              <CopyToClipboard
                text={pixKey}
                onCopy={() => alert('Chave pix copiada para a área de transferência')}>
                <p>{isCopied ? 'Chave pix copiada!' : 'Pagar com pix'}</p>
              </CopyToClipboard>
            </S.PaymentButton>

            <p>{isCopied ? 'No aplicativo do seu banco, ao pagar use a opção "Copia e Cola".' : ''}</p>
          </S.SectionBanner>
        )} */}
        <S.SectionAvatar>
          {user.avatar_url ? (
            <img src={user.avatar_url} alt={user.name} />
          ) : (
            <img src={imgNoProfile} alt="usuário sem foto" />
          )}
        </S.SectionAvatar>

        <S.PersonalInformation className="create-orphanage-form">
          <strong>Perfil</strong>

          <div>
            <span>Nome completo</span>
            {user.name}
          </div>

          <div>
            <span>Email</span>
            <p>
              {userEmailName}
              <wbr />
              {`@${userEmailDomain}`}
            </p>
          </div>

          <div>
            <span>Data de nascimento</span>
            {dateFormatted}
          </div>

          <div>
            <span>CPF</span>
            {maskCPF}
          </div>

          <div>
            <span>Telefone</span>
            {maskPhone}
          </div>

          <div>
            <span>Endereço</span>
            {`${user.address}, ${user.numberHouse} - ${user.neighborhood} - ${user.city}`}
          </div>

          <div>
            <span>Estado</span>
            {user.uf}
          </div>

          <div>
            <span>CEP</span>
            {maskZIP}
          </div>
        </S.PersonalInformation>

        <S.PersonalInformation>
          <strong>Profissional</strong>

          <div>
            <span>Perfil</span>
            {user.occupation}
          </div>

          <div>
            <span>Valor/Plantão</span>
            {maskCost}
          </div>

          <div>
            <span>Bio</span>
            {user.bio}
          </div>

          <div>
            <span>Disponibilidade</span>
            {user.availability}
          </div>

          <div>
            <span>Formação</span>
            {user.formation === 'true' || 'Possui formação'
              ? 'Possui formação'
              : 'Não possui formação'}
          </div>
        </S.PersonalInformation>

        <S.ShareProfile>
          <span>Compartilhar perfil</span>

          <button
            type="button"
            onClick={() =>
              toggleSocialShare(
                `https://app.cuid.com.br/me/${user.slug}`,
                'Veja meu perfil profissional na Cuid: ',
              )
            }
          >
            <FiShare2 />
          </button>
        </S.ShareProfile>

        <S.SupportButton>
          <span>Suporte</span>

          <a
            href="https://api.whatsapp.com/send?phone=5511959984119&text=Olá%2C%20Preciso%20de%20ajuda%20com%20meu%20cadastro%20na%20plataforma%20Cuid."
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp />
          </a>
        </S.SupportButton>
      </S.Content>
    </S.Container>
  );
};

export default Profile;
