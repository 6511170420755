import styled, { css, keyframes } from 'styled-components';
import signInBackground from '../../images/background.png';
import Button from '../../components/Button';

import { shade } from 'polished';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  padding: 0 3.2rem;
  width: 100%;
  max-width: 700px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  place-content: center;

`;

const apperFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${apperFromLeft} 1s;

    img {
      max-width: 30rem;
      max-height: 20.6rem;
    }

    form {
      margin: 3.2rem 0;
      max-width: 36rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      input {
        border: .1rem solid rgb(231, 229, 229);
      }

      h1 {
        margin-bottom: 2.5rem;
        color: ${theme.colors['blue-shade-700']};
      }

      a {
        color: ${theme.colors['blue-shade-700']};
        display: block;
        margin-top: 2.5rem;
        text-decoration: none;

        transition: color 0.2s;

        &:hover {
          color: ${shade(0.3, theme.colors['blue-shade-700'])};
        }
      }
    }

    > a {
      color: ${theme.colors['green-900']};
      display: block;
      margin-top: 16px;
      text-decoration: none;
      transition: color 0.2s;
      display: flex;
      align-items: center;

      svg {
        margin-right: 1.8rem;
      }

      &:hover {
        color: ${shade(0.2, theme.colors['green-900'])};
      }
  }
  `}
`;

export const RecoveryPasswordButton = styled(Button)`
  margin-top: 3.2rem;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackground}) no-repeat center;
  background-size: cover;
`;
