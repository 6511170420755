import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
  isFocused: boolean;
  isFilled: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, isFocused, isFilled, isErrored }) => css`
    position: relative;
    background: ${theme.colors['shade-150']};
    border: 1px solid ${theme.colors['blue-50']};
    border-radius: 0.8rem;
    width: 100%;
    color: ${theme.colors['blue-shade-400']};


    ${isErrored
    && css`
      border-color: ${theme.colors['red-800']};

      span {
        visibility: visible;
      }
    `}

    ${isFilled
    && css`
        border-color: ${theme.colors['green-600']};
      `}

    ${isFocused
    && css`
      border-color: ${theme.colors['green-600']};
      color: ${theme.colors['green-900']};
    `}

    textarea {
      flex: 1;
      width: 100%;
      background: ${theme.colors['shade-150']};
      border: none;
      border-radius: 0.8rem;
      outline: none;
      color: ${theme.colors['blue-shade-300']};

      min-height: 13.2rem;
      max-height: 22.5rem;
      resize: vertical;
      padding: 16px;
      line-height: 2.8rem;

      ${isFilled
    && css`
        color: ${theme.colors['blue-dark-500']};
        border-color: ${theme.colors['green-600']};
      `}

      @media (max-width: 550px) {
        height: 5rem;
        font-size: 1.2.5rem;
      }
    }

    span {
      position: absolute;
      top: 0.6rem;
      right: 2.5rem;

      font-size: 1.2.5rem;
      color: ${theme.colors['red-800']};
    }

    @media (max-width: 720px) {
      .input-block input {
        width: 100%;
      }
    }
  `}
`;
