import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import * as Yup from 'yup';
import * as S from './styles';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/AuthContext';
import { capitalizeUserName } from '../../../utils/formatString';
import Sidebar from '../../../components/Sidebar';
import {
  Send,
  Activity,
  Show,
  Message,
  Notification,
  Paper,
  Danger,
  Download,
  Buy,
} from 'react-iconly';
import {
  FiCheckCircle,
  FiInfo,
  FiMenu,
  FiUploadCloud,
  FiX,
} from 'react-icons/fi';
import { useSocialShare } from '../../../hooks/SocialShareContext';

const imgNoProfile = 'https://app-cuid.s3.amazonaws.com/no-avatar.png';

const Dashboard: React.FC = () => {
  const { toggleSocialShare } = useSocialShare();

  const { user } = useAuth();
  const layUserName = capitalizeUserName(user.name);
  const slug = user.slug;

  const [isOpen, setOpen] = useState(true);
  function showMobileMenu() {
    setOpen(!isOpen);
  }

  const [name] = useState(user.name.toUpperCase());
  const [email] = useState(user.email);
  const [registry_code] = useState(user.cpf);
  const [street] = useState(user.address);
  const [number] = useState(user.numberHouse);
  const [neighborhood] = useState(user.neighborhood);
  const [additional_details] = useState('');
  const [zipcode] = useState(user.zip);
  const [city] = useState(user.city);
  const [state] = useState(user.uf);
  const [whatsapp] = useState(user.whatsapp);
  const [country] = useState('BR');
  const history = useHistory();

  const makePayment = async () => {
    const stripe = await loadStripe(
      'pk_live_51IcJg5FxYI3sgKUsiC2NoBcCJoeWz2bep1hQZ4ZGj0onr7F8gH8koJoBijSzWIry3k7VLiOByKnn7v426j47zqRT00qxUoWYF6',
    );

    const response = await api.post('payments/create-checkout-session');

    const session = await response.data;
    const result = stripe?.redirectToCheckout({
      sessionId: session.id,
    });

    if ((await result)?.error) {
      console.log((await result)?.error);
    }
  };

  function sendNotificationAboutDocs() {
    fetch('https://ntfy.sh/202211_NOTIFICATIONS_ABOUT_CUID', {
      method: 'POST',
      body: `Envio de documentos do usuário ${name} - ${user.email}`,
      headers: {
        Actions: `view, Abrir Whatsapp, https://wa.me/55${whatsapp}?text=Ol%C3%A1!%20Precisa%20de%20ajuda%20para%20verificar%20o%20seu%20perfil?`,
      },
    });
  }

  return (
    <S.Container>
      <div className="top-menu" onClick={showMobileMenu}>
        {isOpen ? <FiX /> : <FiMenu />}
      </div>
      {isOpen ? <Sidebar /> : ''}

      <S.Main>
        <div className="searchbox-container">
          <S.SearchBox>
            <h2>Olá, {layUserName}</h2>
          </S.SearchBox>
          <div className="actions">
            <ul>
              <li>
                <Message set="curved" />
              </li>
              <li>
                <Notification set="curved" />
              </li>
            </ul>
          </div>
        </div>

        {user.payment_status ? (
          <S.UpgradePlan>
            <h1>Seu plano esta ativo!</h1>
            <p>
              Agora você recebe a prioridade nas vagas de parceiros da Cuid!
            </p>
          </S.UpgradePlan>
        ) : (
          <S.UpgradePlan>
            <h1>Potencialize seu perfil!</h1>
            <p>
              Ative o plano premium em seu perfil por R$ 29,90/mês e aumente
              suas chances de conseguir mais plantões!
            </p>
            <button type="submit" onClick={makePayment}>
              Ativar plano
            </button>
          </S.UpgradePlan>
        )}

        {/**        <S.Metrics>
          <div className="metrics-container">
            <div className="card finance unavailable">
              <span>
                <Show set="curved" />
                Visualizações
              </span>
              <strong>EM BREVE</strong>
              <span className="help-text">Pessoas visitaram seu perfil</span>
            </div>
            <div className="card expense unavailable">
              <span>
                <Activity set="curved" />
                Cliques no Whatsapp
              </span>
              <strong>EM BREVE</strong>
              <span className="help-text">
                Pessoas entraram em contato por whatsapp
              </span>
            </div>
            <div className="card expense unavailable">
              <span>
                <Activity set="curved" />
                Cliques no Email
              </span>
              <strong>EM BREVE</strong>
              <span className="help-text">Pessoas entraram em contato por email.</span>
              <span className="help-text">.</span>
            </div>
          </div>
        </S.Metrics>

        <S.Dataset>
          <strong>Documentos e Manuais</strong>

          <table>
            <tr>
              <th>Documento</th>
              <th></th>
              <th></th>
              <th>Criado em</th>
              <th>Download</th>
            </tr>

            <tr>
              <td className="flex">
                <Paper set="curved" />
                Contrato de Trabalho
              </td>
              <td></td>
              <td></td>
              <td>01/04/2023</td>
              <td>
                <Download set="curved" />
              </td>
            </tr>

            <tr>
              <td className="flex">
                <Paper set="curved" />
                Controle de Sinais Vitais
              </td>
              <td></td>
              <td></td>
              <td>01/04/2023</td>
              <div className="more">
                <td>
                  <Download set="curved" />
                </td>
              </div>
            </tr>

            <tr>
              <td className="flex">
                <Paper set="curved" />
                Controle de Horas
              </td>
              <td></td>
              <td></td>
              <td>01/04/2023</td>
              <td>
                <Download set="curved" />
              </td>
            </tr>
          </table>
        </S.Dataset>  */}

        <S.Dataset>
          {user.verified ? (
            <S.VerifiedDocs>
              <div className="docsVerified verified">
                <FiCheckCircle />
                <p>Sua conta foi verificada com sucesso.</p>
              </div>
            </S.VerifiedDocs>
          ) : (
            <S.VerifiedDocs>
              <div className="docsVerified non-verified">
                <FiInfo color="#fabe25" />
                <p>
                  Precisamos validar suas informações, para isso tenha em mãos
                  seu documento de identificação com foto.
                </p>
              </div>

              <S.ContactButton
                type="button"
                onClick={sendNotificationAboutDocs}
              >
                <FiUploadCloud />
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdZAJlz86P_NEcDF4ZBfryCkR4rsE-mDA3HwsXCL8ddl3o32Q/viewform?usp=sf_link"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Enviar documentos
                </a>
              </S.ContactButton>
            </S.VerifiedDocs>
          )}
        </S.Dataset>
      </S.Main>

      <S.RSideBar className="ordering">
        <S.Profile>
          <S.User>
            {user.avatar_url ? (
              <img src={user.avatar_url} alt={user.name} />
            ) : (
              <img src={imgNoProfile} alt={user.name} />
            )}
            <strong>{layUserName}</strong>
            <p>{user.occupation}</p>

            <div className="actions">
              <ul>
                <Link to={`perfil/${user.slug}`}>
                  <div className="tooltip">
                    <span className="tooltiptext">Ver perfil</span>
                    <li>
                      <Show set="curved" />
                    </li>
                  </div>
                </Link>

                <div
                  className="tooltip"
                  onClick={() =>
                    toggleSocialShare(
                      `https://app.cuid.com.br/me/${slug}`,
                      'Veja meu perfil profissional na Cuid',
                    )
                  }
                >
                  <span className="tooltiptext">Compartilhar</span>
                  <li>
                    <Send />
                  </li>
                </div>

                <div className="tooltip">
                  <span className="tooltiptext">Baixar Currículo</span>
                  <li>
                    <Download />
                  </li>
                </div>
              </ul>
            </div>
            <div className="divider"></div>
          </S.User>
          <S.About>
            <strong>Sobre mim</strong>
            <p>{user.bio}</p>
          </S.About>
        </S.Profile>

        {/**
         * <S.StatusAccont>
          <strong>Seu Perfil</strong>
          <p>
            Para aumentar suas chances de conseguir um plantão, revise os itens
            abaixo:
          </p>
          <ul>
            <li>
              <Danger set="curved" />
              <div>
                <p>Foto de perfil</p>
              </div>
            </li>
            <li>
              <Danger set="curved" />
              <div>
                <p>Whatsapp</p>
              </div>
            </li>
            <li>
              <Danger set="curved" />
              <div>
                <p>Resumo</p>
              </div>
            </li>
            <li>
              <Danger set="curved" />
              <div>
                <p>Valor do plantão</p>
              </div>
            </li>
          </ul>
        </S.StatusAccont>
         *
         */}
      </S.RSideBar>
    </S.Container>
  );
};

export default Dashboard;
