import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%auto;
  padding: 0 24px;
  a {
    color: #fff;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #04d361;
    padding: 10px;
    border-radius: 5px;
    &:hover {
      color: ${shade(0.2, '#fff')}
    }
    > svg {
    margin-right: 10px;
    }
  }
`;

export const Title = styled.text`
  font-size: 32px;
  color: #04d361;
  text-align: center;
  `;

export const Description = styled.text`
margin-top: 3.2rem;
color: #344;
text-align: center;
width: 58.75rem;
line-height: 3rem;
`;

export const Confetti = styled.div`
`;
