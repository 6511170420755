import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, isErrored }) => css`
  border: 1px solid;
  border-color: ${theme.colors['blue-50']};
  border-radius: 0.5rem;
  color: ${theme.colors['blue-shade-300']};
  position: relative;

    span {
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;

      font-size: 1.2.5rem;
      color: ${theme.colors['red-800']};
    }

  ${isErrored
    && css`
      border-color: ${theme.colors['red-800']};

      span {
        visibility: visible;
      }
  `}

  &:focus-within {
    border-color: ${theme.colors['green-900']};
  }

  .react-select__menu-list:hover {
    background-color: red,
  }
  `}

`;
