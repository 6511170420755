import React from 'react';
import { FiSearch } from 'react-icons/fi';

import * as S from './styles';

function NoResult() {

  return (
    <S.Container>
      <FiSearch />
      <p>Tente mudar os filtros selecionados para encontrar novos resultados.</p>
    </S.Container>


  );
}
export default NoResult;
