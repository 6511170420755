export const optionsAvailability = [
  { value: '', label: 'Selecionar disponibilidade..' },
  { value: 'Diarista', label: 'Diarista' },
  { value: 'Folguista', label: 'Folguista' },
  { value: 'Mensalista', label: 'Mensalista' }
];

export const optionsOccupation = [
  { value: '', label: 'Selecionar perfil...' },
  { value: 'Auxiliar de Enfermagem', label: 'Auxiliar de Enfermagem' },
  { value: 'Babá', label: 'Babá' },
  { value: 'Cuidador de Idosos', label: 'Cuidador de Idosos' },
  { value: 'Técnico de Enfermagem', label: 'Técnico de Enfermagem' },
];

export const optionsGenre = [
  { value: '', label: 'Selecionar gênero...' },
  { value: 'Feminino', label: 'Feminino' },
  { value: 'Masculino', label: 'Masculino' },
];

export const optionsEntity = [
  { value: '', label: 'Selecionar perfil' },
  { value: 'Familia', label: 'Sou Familiar' },
  { value: 'Empresa', label: 'Sou Empresa' },
];
