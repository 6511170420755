export function getFirstAndMiddleName(fullName: string) {
  const [nome] = fullName.split(' ');

  const findSpace = fullName.indexOf(' ') + 1;
  if (findSpace < 0) {
    return nome;
  }

  let initialSecondName = fullName[findSpace].concat('.');
  return nome.concat(' ', initialSecondName).toUpperCase();
}

export function capitalizeUserName(fullName: string) {
  return fullName.toLowerCase();
}

export function getFormatNeighborhood(neighborhood: string) {
  const arr = neighborhood.split(' ');

  let formattedNeighborhood;

  if (arr.length < 2) {
    formattedNeighborhood = arr[0];
  }

  if (arr.length === 2) {
    formattedNeighborhood = arr[0].concat(' ', arr[1]);
  }

  if (arr.length >= 3) {
    formattedNeighborhood = arr[0].concat(` ${arr[1]}...`);
  }

  return formattedNeighborhood;
};
