import styled, { css } from 'styled-components';

import { Tooltip } from '../../components/Tooltip';
import Button from '../../components/Button';

export const Container = styled.div``;

export const Content = styled.main`
  ${({ theme }) => css`
    flex: 1;
    margin: 0 auto;
    padding: 0 3.2rem;

    form {
      max-width: 700px;
      margin: 6.2rem auto;

      display: flex;
      flex-direction: column;

      border-radius: 0.5rem;
      border: 1px solid ${theme.colors['blue-50']};
      background: ${theme.colors.white};
      padding: 5rem 8rem;

      overflow: hidden;
      input {
        border: 0.1rem solid rgb(231, 229, 229);
      }
      fieldset {
        border: none;

        & + fieldset {
          margin-top: 8rem;
        }

        legend {
          width: 100%;

          font-size: 3.2rem;
          line-height: 2.5rem;
          font-weight: 400;
          color: ${theme.colors['blue-shade-400']};

          border-bottom: 1px solid ${theme.colors['blue-50']};
          margin-bottom: 2.5rem;
          padding-bottom: 2.5rem;
        }

        .update-password {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${theme.colors['blue-shade-400']};
          margin: 4rem 0 0;
        }
      }

      @media (max-width: 600px) {
        padding: 3.2rem 2.5rem;
        margin: 3.2rem auto;
      }

      @media (max-width: 420px) {
        padding: 1.8rem 3.2rem;

        fieldset {
          border: 0;

          & + fieldset {
            margin-top: 2.5rem;
          }

          legend {
            width: 100%;

            font-size: 3.2rem;
            line-height: 2.8rem;
            margin-bottom: 3.2rem;
            padding-bottom: 1.3.2rem;
          }
        }
      }
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    display: flex;
    align-items: baseline;
    color: ${theme.colors['blue-shade-200']};
    margin: 2.5rem 0 0.8rem;
    line-height: 2.5rem;

    span {
      font-size: 1.2.5rem;
      color: ${theme.colors['blue-shade-200']};
      margin-left: 2.5rem;
      line-height: 2.5rem;
    }

    @media (max-width: 420px) {
      margin: 3.2rem 0 0.6rem;
      line-height: 2.5rem;

      span {
        font-size: 1.2.5rem;
        color: ${theme.colors['blue-shade-200']};
        margin-left: 3.2rem;
        line-height: 3.2rem;
      }
    }
  `}
`;
export const Avatar = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 20rem;
      height: 20rem;
      position: relative;
    }

    img {
      width: 20rem;
      height: 20rem;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 1.8rem;
    }

    label {
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 50%;
      margin: 0;
      line-height: 0;
      background: ${theme.colors['green-600']};
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      right: -1.5rem;
      bottom: 2.5rem;

      transition: filter 0.2s;

      svg {
        width: 2.5rem;
        height: 2.5rem;
        color: ${theme.colors.white};
      }

      &:hover {
        filter: brightness(0.8);
      }
    }

    input {
      display: none;
    }
  `}
`;

export const WrapperSlugEdit = styled.section`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors['blue-50']};
    border-bottom: 1px solid ${theme.colors['blue-50']};
    padding: 5rem 0;
    margin: 5rem 0;

    strong {
      display: flex;
      align-items: center;
      margin-bottom: 0.8rem;
      color: ${theme.colors['blue-shade-400']};

      > svg {
        width: 3.2rem;
        height: 3.2rem;
        margin-right: 1rem;
      }
    }
  `}
`;

export const personalizeLink = styled.span`
  ${({ theme }) => css`
    align-items: baseline;
    font-size: 1.2.5rem;
    font-weight: 500;
    color: ${theme.colors['blue-shade-400']};
    filter: brightness(0.7);
    margin-top: 0.8rem;

    cursor: help;

    span {
      color: ${theme.colors['green-600']};
    }

    svg {
      width: 1.6rem;
      height: 1.6rem;
      margin-left: 1rem;
    }
  `}
`;

export const UpdateButton = styled(Button)`
  margin-top: 2.5rem;
`;

export const Information = styled(Tooltip)`
  &::after {
    max-width: 220px;

    left: 0%;
    transform: translateX(-0%);
  }

  &::before {
    left: 35%;
    transform: translateX(-35%);
  }
`;
