import React, { useState } from 'react';
import * as S from './styles';
import { useAuth } from '../../../hooks/AuthContext';
import { capitalizeUserName } from '../../../utils/formatString';
import Sidebar from '../../../components/Sidebar';
import {
  Message,
  Notification,
} from 'react-iconly';
import { FiMenu, FiX } from 'react-icons/fi';


const DashboardPartners: React.FC = () => {

  const { user } = useAuth();
  const layUserName = capitalizeUserName(user.name);

  const [isOpen, setOpen] = useState(true)
  function showMobileMenu() {
    setOpen(!isOpen)
  }

  return (
    <S.Container>
      <div className="top-menu" onClick={showMobileMenu} >
        {isOpen ? <FiX /> : <FiMenu />}
      </div>
      {isOpen
        ? (
          <Sidebar />
        )
        :
        ''}

      <S.Main>
        <div className='searchbox-container'>
          <S.SearchBox>
            <h2>Olá, {layUserName}</h2>
          </S.SearchBox>
          <div className="actions">
            <ul>
              <li>
                <Message
                  set='curved'
                />
              </li>
              <li>
                <Notification
                  set='curved'
                />
              </li>
            </ul>
          </div>
        </div>

        <div>
          <div>
            <h2>Conheça nossos parceiros!</h2>
            <h4>Conheça os serviços dos nossos parceiros e veja como eles podem facilitar a sua jornada.</h4>
          </div>
          <br />
          <input type="text" name="search" id="search" placeholder="Busque por categoria" />
        </div>

        <S.Metrics >
          <div className="metrics-container">
            <div className='card finance unavailable'>
              <strong>Toledo & Sorocaba Advocacia</strong>
              <span className="help-text badge">Advocacia</span>
            </div>
            <div className='card finance unavailable'>
              <strong>Paula Psicopedagoga</strong>
              <span className="help-text badge">Psicopedagogia</span>
            </div>
            <div className='card finance unavailable'>
              <strong>Nome Parceiro</strong>
              <span className="help-text badge">Categoria</span>
            </div>
          </div>
        </S.Metrics>

      </S.Main>

    </S.Container>
  );
};

export default DashboardPartners;


