import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';

import logoImg from '../../images/logo-cuid.png';
import logoABC from '../../images/abc-logo.png';

import * as S from './styles';

type HeaderProps = {
  path?: string;
};

function previousPage() {
  window.history.back()
}

export default function Header({ path }: HeaderProps): JSX.Element {
  const isPartner = window.location.pathname.includes('abc-cuidadores');

  return (
    <S.Container>
      <S.Content>
        <img src={logoImg} alt="Logo da plataforma Cuid" />
        {isPartner && <img src={logoABC} alt="Logo da ABC Cuidadores" />}

        <button onClick={previousPage}>
          <FiArrowLeft />
          Voltar
        </button>
      </S.Content>
    </S.Container>
  );
}
