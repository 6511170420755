import { shade } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 5rem 3.2rem;

  @media (max-width: 600px) {
    padding: 3.2rem 3.2rem;
    height: 210rem;
  }
`;

export const Content = styled.main`
  ${({ theme }) => css`
    margin: 0 auto;
    padding: 7rem;
    max-width: 800px;
    border-radius: 1.2rem;
    border: 1px solid #eee;
    background: #fff;
    box-shadow: 0 2px 9px 0 rgb(158 184 209 / 41%);

    @media (max-width: 800px) {
      padding: 6rem;
    }

    @media (max-width: 700px) {
      padding: 5rem;
    }

    @media (max-width: 600px) {
      padding: 2.5rem;
    }

    @media (max-width: 500px) {
      padding: 3.2rem;
    }
  `}
`;

export const WrapperShare = styled.div`
  ${({ theme }) => css`
    button {
      background: transparent;
      border: none;
      font-size: 1.2.5rem;
      font-weight: bold;
      color: ${theme.colors['blue-shade-400']};

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 0.8rem;
        width: 2.5rem;
        height: 2.5rem;
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  `}
`;

export const Avatar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0;

  img {
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const DetailsContainer = styled.section`
  ${({ theme }) => css`
    h1 {
      color: ${theme.colors['blue-shade-400']};
      font-size: 5.2.5rem;
      line-height: 5.2.5rem;
      margin-bottom: 0.8rem;
    }

    p {
      margin-top: 2.5rem;
      line-height: 2.8rem;
      color: ${theme.colors['blue-shade-300']};
    }

    h2 {
      margin-top: 2.5rem;
      font-size: 3.6rem;
      line-height: 6rem;
      color: ${theme.colors['blue-shade-400']};
    }

    @media (max-width: 600px) {
      h1 {
        font-size: 5rem;
      }

      h2 {
        font-size: 2.3.2rem;
      }
    }

    @media (max-width: 500px) {
      h1 {
        font-size: 2.5rem;
      }

      h2 {
        font-size: 3.2rem;
      }
    }
  `}
`;

export const WrapperMap = styled.section`
  ${({ theme }) => css`
    margin-top: 5rem;
    background: ${theme.colors['blue-light-120']};
    border: 1px solid ${theme.colors['blue-light-100']};
    border-radius: 3.2rem;

    footer {
      padding: 3.2rem 0;
      line-height: 2.5rem;
      color: ${theme.colors['blue-light-900']};

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 1rem;
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    @media (max-width: 420px) {
      font-size: 1.2.5rem;

      svg {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  `}
`;

export const WrapperCards = styled.section`
  margin-top: 3.2rem;
  display: flex;
  align-items: center;
  gap: 3.2rem;

  div {
    width: 100%;
    min-height: 20rem;
    height: auto;
    padding: 3.2rem 2.5rem;
    border-radius: 3.2rem;
    line-height: 2.8rem;

    background: linear-gradient(154.16deg, #edfff6 7.85%, #ffffff 91.03%);
    border: 1px solid #a1e9c5;
    color: #37c77f;

    display: flex;
    flex-direction: column;

    svg {
      margin-bottom: 3.2rem;
      width: 3.2rem;
      height: 3.2rem;
    }

    .hour {
      background: linear-gradient(149.97deg, #e6f7fb 8.13%, #ffffff 92.67%);
      border: 1px solid #b3dae2;
      color: #1c3438;
    }

    .dont-open {
      background: linear-gradient(154.16deg, #fdf8f5 7.85%, #ffffff 91.03%);
      border: 1px solid #ffbcd4;
      color: #ff6690;

      svg {
        color: #ff6690;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    margin-top: 2rem;
    padding-top: 5.2rem;
  }
`;

export const ContactButtons = styled.div`
  margin-top: 5rem;

  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 3.2rem;

  button {
    max-width: 50%;
    width: 100%;
    height: 5rem;
    padding: 0 1.8rem;
    border: 0;
    cursor: pointer;
    background: rgb(4, 211, 97);
    border-radius: 1rem;
    color: #ffffff;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background-color 0.2s;

    > svg {
      margin-right: 1rem;
      width: 2.5rem;
      height: 2.5rem;
      color: #ffffff;
    }

    &:hover {
      background: ${shade(0.2, '#3cdc8c')};
    }

    &:last-child {
      background: #1c3438;

      &:hover {
        background: ${shade(0.2, '#1c3438')};
      }
    }
  }

  @media (max-width: 610px) {
    flex-direction: column;
    position: fixed;
    float: left;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
    justify-content: left;
    align-items: left;
    z-index: 10;
    background: #fff;

    button {
      max-width: 100%;
      height: 7rem;
      border-radius: 4px;
      font-size: 2rem;

      svg {
        width: 3rem;
        height: 3rem;
        left: 0;
      }
    }
  }
`;

export const ContactButton = styled.button`
  ${({ theme }) => css`
    margin: 4rem auto 0;
    border: none;
    border-radius: 5rem;
    background: transparent;
    color: ${theme.colors['blue-shade-400']};
    display: flex;
    align-items: center;
    gap: 1rem;
    svg {
      width: 2rem;
      height: 2rem;
    }
  `}
`;
