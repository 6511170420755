import styled from 'styled-components';

export const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
background: #FFFFFF;
color: #000000;
font-family: 'Manrope', sans-serif;
border-top: 1px solid #F3F4F6;

@media (max-width: 720px) {
  padding: 0 4rem;
}
`;

export const Main = styled.main`
max-width: 1120px;

@media (max-width: 720px) {
  width: 100%;
  max-width: 100%;
}
`;

export const Name = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
margin: 3.2rem 0;

h1 {
  text-transform: capitalize;
}

.active {
  svg {
    fill: red;
  }
}


> div {
  display: flex;
  gap: 2rem;
  font-size: 1.5rem;
  span {
    display: flex;
    align-items: center;
    gap: 1rem;

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  button {
      font-weight: 500;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.6rem;
      padding: 1.6rem;
      font-size: 1.4rem;
      background: transparent;
      border-radius: .8rem;
      color: rgb(3 7 18);
      border: 1px solid rgb(74 222 128);
    }
}

@media (max-width: 720px) {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 3rem;
  font-size: 2rem;

  .actions {
    margin-top: -8rem;
    width: 100%;
    top: 11rem;
    right: .5rem;
    font-size: 2rem;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: right;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      background: rgba(255, 255, 255, .8);
      border-radius: 9999px;
      width: 50px;
      border: 1px solid rgba(255, 255, 255, .8);

      span {
        display: none;
      }
    }

    & :hover {
      filter: brightness(.9);
    }
  }
}
`;

export const Gallery = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: stretch;
margin: 4rem 0;
gap: 1rem;

.carousel {
  display: flex;
  gap: .5rem;

  .slider {
    display: none;
  }
}

.hero {
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px 0 0 8px;
  }
}

.grid {
  display:grid;
  grid-template-columns: 274px 274px;
  gap: .5rem;

  img {
    width: 274px;
    height: 100%;
    object-fit: cover;
  }
  & :nth-child(2) {
    border-radius: 0 8px 0 0;
  }
  & :nth-child(4) {
    border-radius: 0 0 8px 0;
  }
}

@media (max-width: 720px) {
  width: 100%;
  border-radius: 0;

  .grid {
    display: flex;
  }
  .carousel {
    overflow: auto;
    display: flex;
    scroll-snap-type: x mandatory;

    .slider {
      width: 1150px;
      font-weight: 600;
      font-size: 1.6rem;
      display: flex;
      flex-direction: column;
      margin: auto 2rem ;

      svg {
        margin-left: auto;
      }

      >div {
        display: flex;
        align-items: center;
        justify-content: start;
      }
    }

    img {
      flex-shrink: 0;
      scroll-snap-align: start;
      width: 100%;
      height: 220px;
      object-fit: cover;
      border-radius: 0;
    }

    & :nth-child(2) {
      border-radius: 0;
    }
    & :nth-child(4) {
      border-radius: 0;
    }
  }
}
`;

export const Content = styled.div`
display: flex;
justify-content: space-between;
align-items: flex-start;
gap: 8rem;

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .divider {
    width: 100%;
    height: 1px;
    margin: 3.2rem 0;
    background: #E5E7EB;
  }
}

.details-title {
  width: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 2.4rem;
    margin: 0 0 3.2rem 0;

  }

.service-items {
    display: flex;
        }
}

.details-location {
  width: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 2.4rem;
    margin: 0 0 3.2rem 0;

  }

  .service-items {
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      ul {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        li {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 1.6rem;
          width: 100%;
          list-style: disc;
        }
      }
    }
  }

}

.details-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  .details-item {
    height: 44px;
    display: flex;
    gap: 1rem;
    align-items: start;

    div {
      display: flex;
      flex-direction: column;
    }

    strong {
      font-size: 1.6rem;
    }

    span {
      color: #6B7280;
      font-size: 1.4rem;
    }

    svg {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
}

.details-resume {
  line-height: 2.4rem;

  a {
    margin: 1rem 0;
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.service-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    width: 100%;
  }

  ul li {
    display: flex;
    gap: 2rem;
    margin: 2rem 0;
    align-items: flex-start;
    justify-content: start;
    list-style: none;
    width: 100%;
  }
}

/* .tags {
  span:nth-child(1n + 2)::after{
    content: ' • ';
  }
} */

.contact {
  position: sticky;
  top: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    width: 37rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 2.4rem;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    box-shadow: 0px 20px 25px rgba(31, 41, 55, 0.1), 0px 10px 10px rgba(31, 41, 55, 0.04);

    > strong {
      font-size: 2rem;
      line-height: 3.2rem;
      font-weight: bold;
    }

    .container-picker {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid #D1D5DB;
      border-radius: 8px;
      width: 322px;

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .picker {
        display: flex;
        padding: .8rem;
        flex-direction: column;
        align-items: flex-start;

        label {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 2rem;
          text-transform: uppercase;
          color: smokegray;
        }

        input, select, textarea {
          font-weight: 400;
          width: 100%;
          font-size: 1.6rem;
          outline: 0;
          background: transparent;
          color: #000000;
          resize: none;
        }
      }

      .spacer {
        width: 1px;
        background: #D1D5DB;
      }

      .line-top{
        border-top: 1px solid #D1D5DB;
      }
    }

    button {
      width: 100%;
      height: 44px;
      border-radius: 8px;
      font-weight: 500;
      font-size: 1.5rem;
      background: #04d361;
      color: #ffffff;
      transition: .2s ease-in;

      &:hover {
        filter: brightness(.9);
      }
    }
  }

  > span {
    margin-top: 2.4rem;
    gap: .8rem;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6b7280;
  }
}

@media (max-width: 720px) {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: 3rem;

  label {
          font-size: 2rem;
        }

  .details {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .contact {
    width: 100%;
    font-size: 2rem;

    form {
      width: 100%;

      .container-picker {
        width: 100%;
      }
    }

    .details-location {
      width: 100%;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 2.4rem;
        margin: 0 0 3.2rem 0;

      }

      .service-items {
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          ul {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;

            li {
              display: flex;
              align-items: center;
              justify-content: start;
              gap: 1.6rem;
              width: 100%;
              list-style: disc;
            }
          }
        }
      }
    }

    .grid {
      display: flex;
      flex-direction: column;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px 0 0 8px;
      }
    }
  }
.service-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;

  > div {
  width: 100%;
  }

  ul li {
    display: flex;
    gap: 2rem;
    align-items: flex-start;
    justify-content: start;
    list-style: none;
    width: 100%;
    }
  }
}

`;

export const WrapperMap2 = styled.div`
flex-direction: column;
align-items: center;
justify-content: center;
display: flex;
width: 100%;
border: 1px solid #E5E7EB;
border-radius: 1.2rem;
padding: 1.6rem;

footer {
  gap: 1.6rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b7280;
  font-weight: 500;
}
`;
