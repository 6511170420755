import React from 'react';
import { FiCheckCircle, FiLogIn } from 'react-icons/fi';

import Confetti from 'react-confetti';

import { Container, Title, Description } from './styles';

const Success: React.FC = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  return (
    <Container>
      <FiCheckCircle size={100} color="#04d361" />
      <Title>Parabéns por assinar nosso plano.</Title>
      <Description>
        <h3>Agora seu perfil já está disponível para todo o Brasil.</h3>
        <br />
        A Cuid nasceu para te dar mais autonomia sobre seus plantões, defina
        quanto quer cobrar pelo serviço, dia e hora que quer trabalhar, sem
        intermédio de agências.
        <br />
      </Description>

      <a href="/dashboard">
        <FiLogIn />
        Voltar ao perfil
      </a>
      <Confetti width={width} height={height} />
    </Container>
  );
};

export default Success;
