import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { TooltipStyle } from './styles';
import * as React from 'react';

interface ChildProps {
  copy: (content: any) => void;
}

interface Props {
  TooltipProps?: Partial<TooltipProps>;
  children: (props: ChildProps) => React.ReactElement<any>;
}

interface OwnState {
  showTooltip: boolean;
}

/**
 * Render prop component that wraps element in a Tooltip that shows "Copied to clipboard!" when the
 * copy function is invoked
 */
class CopyToClipboard extends React.Component<Props, OwnState> {
  public state: OwnState = { showTooltip: false };

  public render() {
    return (
      <TooltipStyle>
        <Tooltip
          open={this.state.showTooltip}
          title={'Link copiado!'}
          leaveDelay={1500}
          onClose={this.handleOnTooltipClose}
          {...(this.props.TooltipProps || {})}
        >
          {
            this.props.children({
              copy: this.onCopy,
            }) as React.ReactElement<any>
          }
        </Tooltip>
      </TooltipStyle>
    );
  }

  private onCopy = (content: any) => {
    this.setState({ showTooltip: true });
    navigator.clipboard.writeText(content);
  };

  private handleOnTooltipClose = () => {
    this.setState({ showTooltip: false });
  };
}

export default CopyToClipboard;
