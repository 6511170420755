// eslint-disable-next-line @typescript-eslint/no-explicit-any

import React, { useEffect, useRef, useState } from 'react';
import { FiSliders, FiLayers, FiX, FiMenu } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import * as S from './styles';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import SearchBox from './SearchBox';
import api from '../../../services/api';
import Input from '../../../components/Input';

interface Professional {
  address: string;
  availability: string;
  avatar: string;
  avatar_url: string;
  bio: string;
  birthday: string;
  city: string;
  coren: string | null;
  coren_status: string | null;
  cost: string;
  cpf: string;
  created_at: string;
  email: string;
  formation: string;
  genre: string;
  id: string;
  latitude: string;
  longitude: string;
  name: string;
  neighborhood: string;
  numberHouse: string;
  occupation: string;
  origin: string;
  payment_status: boolean;
  report: boolean;
  slug: string;
  uf: string;
  verified: boolean;
  visibility: boolean;
  whatsapp: string;
  zip: string;
}

interface ProfessionalWithDistance extends Professional {
  distance?: number;
}

type SubmitFormData = {
  name: string;
  whatsapp: string;
  email: string;
  entity: string;
  permissionContact: boolean;
};

const filtersOptions = [
  { name: 'Todos', value: '' },
  { name: 'Cuidadores de Idosos', value: 'cuidador de idosos' },
  { name: 'Auxiliares de Enfermagem', value: 'auxiliar de enfermagem' },
  { name: 'Técnicos de Enfermagem', value: 'técnico de enfermagem' },
  { name: 'Babás', value: 'babá' },
];

export default function SearchProfessionals() {
  const history = useHistory();
  const location = useLocation();

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          // Usa a localização atual do usuário para buscar profissionais ou centralizar o mapa
          handleSearch({ lat: latitude, lng: longitude });
        },
        error => {
          alert('Não foi possível obter sua localização.');
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      );
    } else {
      alert('Geolocalização não é suportada pelo seu navegador.');
    }
  };

  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const [selectedProfessional, setSelectedProfessional] =
    useState<Professional | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<string>('');
  const [currentLocation, setCurrentLocation] = useState<{
    lat: number;
    lng: number;
  } | null>({
    lat: -23.5871239,
    lng: -46.6821986,
  });
  const [isPendingFetch, setIsPendingFetch] = useState(true);

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const selectedMarkerRef = useRef<mapboxgl.Marker | null>(null);
  const lineLayerRef = useRef<string>('route-line'); // Ref para o ID da camada da linha

  useEffect(() => {
    if (mapContainerRef.current) {
      mapboxgl.accessToken =
        'pk.eyJ1Ijoia2VsdmluNDAxIiwiYSI6ImNrZ3NsczIwejAwaXkycW8wMzlydzVramgifQ.VAmIskjRXkkPzqK3Ob2Npg';
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-46.6821986, -23.5871239],
        zoom: 12,
      });

      mapRef.current?.addControl(new mapboxgl.NavigationControl());

      return () => {
        mapRef.current?.remove();
      };
    }
  }, []);

  // Função para obter a cidade de busca do localStorage
  const getSearchCity = () => {
    let searchCity = localStorage.getItem('@Cuid:search_city');

    // Se não houver cidade no localStorage, define 'São Paulo' como padrão
    if (!searchCity) {
      searchCity = 'São Paulo';
      localStorage.setItem('@Cuid:search_city', searchCity);
    }

    return searchCity;
  };

  // Usar a função para obter a cidade
  const cidadePesquisa = getSearchCity();

  async function fetchProfessionals(
    price = '',
    gender = '',
    profissional = '',
    cidade: string = cidadePesquisa, // Parâmetro para a cidade
  ) {
    try {
      const response = await fetch(
        `https://api.cuid.com.br/cadastro/profissionais/busca/${encodeURIComponent(
          cidade,
        )}?genero=${gender}&ocupacao=${profissional}&formacao=&preco=${price}`,
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const { caregiver } = await response.json();
      setProfessionals(caregiver);
      setIsPendingFetch(false);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }

  useEffect(() => {
    fetchProfessionals(); // Chama a função para buscar os dados
  }, []);
  const markersRef = useRef<mapboxgl.Marker[]>([]); // Definição da referência para os marcadores

  useEffect(() => {
    if (mapRef.current && professionals.length > 0) {
      // Remove marcadores existentes antes de adicionar novos

      markersRef.current.forEach(marker => marker.remove());
      markersRef.current = [];

      professionals.forEach(professional => {
        const lat = parseFloat(professional.latitude);
        const lng = parseFloat(professional.longitude);

        if (mapRef.current && !isNaN(lat) && !isNaN(lng)) {
          const marker = new mapboxgl.Marker()
            .setLngLat([lng, lat])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }).setHTML(`
              <div class="popup-container">
            <div>
            ${
              professional.avatar_url
                ? `<img class="avatar popup-img" src="${professional.avatar_url}" alt="${professional.name}" />`
                : '<img class="avatar popup-img" src="https://app-cuid.s3.amazonaws.com/no-avatar.png" alt="perfil sem foto" />'
            }
            </div>
            <div class="popup-content">
            <h3>${
              professional.name.charAt(0).toUpperCase() +
              professional.name.slice(1).toLowerCase().split(' ')[0]
            }</h3>
              <p>${professional.occupation}</p>
              <p>${professional.neighborhood} - ${professional.uf}</p>
            <p>A partir de ${parseCost(professional.cost).toLocaleString(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            )}</p>
              <div>
              <a href=${`https://app.cuid.com.br/me/${professional.slug}`}>Ver perfil</a>
              </div>
            </div>
          </div>
              `),
            )
            .addTo(mapRef.current);

          markersRef.current.push(marker);
        }
      });
    }
  }, [professionals]);

  useEffect(() => {
    if (selectedProfessional && mapRef.current) {
      const lat = parseFloat(selectedProfessional.latitude);
      const lng = parseFloat(selectedProfessional.longitude);

      if (!isNaN(lat) && !isNaN(lng)) {
        // Remove marcador anterior se existir
        if (selectedMarkerRef.current) {
          selectedMarkerRef.current.remove();
        }

        // Adiciona novo marcador
        selectedMarkerRef.current = new mapboxgl.Marker({ color: '#15803d' })
          .setLngLat([lng, lat])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }).setHTML(`
            <div class="popup-container">
            <div>
            ${
              selectedProfessional.avatar_url
                ? `<img class="avatar popup-img" src="${selectedProfessional.avatar_url}" alt="${selectedProfessional.name}" />`
                : '<img class="avatar popup-img" src="https://app-cuid.s3.amazonaws.com/no-avatar.png" alt="perfil sem foto" />'
            }
            </div>
            <div class="popup-content">
            <h3>${
              selectedProfessional.name.charAt(0).toUpperCase() +
              selectedProfessional.name.slice(1).toLowerCase().split(' ')[0]
            }</h3>
              <p>${selectedProfessional.occupation}</p>
              <p>${selectedProfessional.neighborhood} - ${
              selectedProfessional.uf
            }</p>
            <p>A partir de ${parseCost(
              selectedProfessional.cost,
            ).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}</p>
              <div>
               <a href=${`https://app.cuid.com.br/me/${selectedProfessional.slug}`}>Ver perfil</a>
              </div>
            </div>
          </div>
            `),
          )
          .addTo(mapRef.current);

        // Centraliza o mapa no profissional selecionado
        mapRef.current.flyTo({
          center: [lng, lat],
          zoom: 15,
          essential: true,
        });

        // Desenha a linha entre o local do usuário e o profissional selecionado
        if (currentLocation) {
          const lineCoordinates = [
            [currentLocation.lng, currentLocation.lat], // Ponto de partida
            [lng, lat], // Ponto de chegada
          ];

          if (mapRef.current.getLayer(lineLayerRef.current)) {
            mapRef.current.removeLayer(lineLayerRef.current);
            mapRef.current.removeSource(lineLayerRef.current);
          }

          mapRef.current.addSource(lineLayerRef.current, {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: lineCoordinates,
              },
              properties: {}, // Adiciona uma propriedade vazia para atender ao tipo esperado
            },
          });

          mapRef.current.addLayer({
            id: lineLayerRef.current,
            type: 'line',
            source: lineLayerRef.current,
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#15803d',
              'line-width': 3,
            },
          });
        }
      }
    }
  }, [selectedProfessional, professionals, currentLocation]);

  const handleFilterClick = (value: string) => {
    const currentFilters = new URLSearchParams(location.search);
    const filterParam = 'profissional';

    if (selectedFilter === value) {
      // Remove o filtro se ele já estiver selecionado
      currentFilters.delete(filterParam);
    } else {
      // Adiciona ou atualiza o filtro
      currentFilters.set(filterParam, value);
    }

    // Atualiza a URL com os filtros
    history.push(`/?${currentFilters.toString()}`);
    setSelectedFilter(value);
  };

  const getQueryStringValue = (key: string) =>
    new URLSearchParams(location.search).get(key);

  useEffect(() => {
    const filterFromUrl = getQueryStringValue('profissional') || '';
    setSelectedFilter(filterFromUrl);
  }, [location.search]);

  const calculateDistance = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number,
  ) => {
    const toRad = (value: number) => (value * Math.PI) / 180;
    const R = 6371; // km
    const dLat = toRad(lat2 - lat1);
    const dLng = toRad(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const maxDistance = 15; // Definindo o máximo de 15km

  const filteredProfessionals: ProfessionalWithDistance[] = professionals
    .filter(
      professional =>
        selectedFilter === '' ||
        professional.occupation
          .toLowerCase()
          .includes(selectedFilter.toLowerCase()),
    )
    .map(professional => {
      if (currentLocation) {
        const distance = calculateDistance(
          currentLocation.lat,
          currentLocation.lng,
          parseFloat(professional.latitude),
          parseFloat(professional.longitude),
        );
        // Adiciona a distância calculada ao objeto do profissional
        return { ...professional, distance };
      }
      return professional as ProfessionalWithDistance;
    })
    .filter(
      professional =>
        // Filtrando apenas os profissionais que estão a no máximo 15km
        professional.distance !== undefined &&
        professional.distance <= maxDistance,
    ) // Aqui você precisava fechar o parêntese
    .sort((a, b) => {
      // Ordena primeiro por quem tem foto e depois por distância
      if (a.avatar_url && !b.avatar_url) return -1;
      if (!a.avatar_url && b.avatar_url) return 1;
      return (a.distance ?? Infinity) - (b.distance ?? Infinity);
    });

  const handleCardClick = (professional: Professional) => {
    setSelectedProfessional(professional);
  };

  const parseCost = (costString: string) => {
    const cleanedCost = costString.replace(/[^\d,]/g, '');
    const numberCost = parseFloat(cleanedCost.replace(',', '.'));
    return numberCost / 100;
  };

  const handleSearch = async (location: { lat: number; lng: number }) => {
    setCurrentLocation(location);

    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [location.lng, location.lat],
        zoom: 15,
      });
    }

    // Exemplo de conversão de coordenadas para cidade (aqui usando uma função fictícia)
    const cidade = await getCityFromCoordinates(location.lat, location.lng);

    // Chama a função fetchProfessionals com a cidade
    fetchProfessionals('', '', '', cidade);
  };

  // Função de exemplo para obter a cidade a partir das coordenadas
  async function getCityFromCoordinates(
    lat: number,
    lng: number,
  ): Promise<string> {
    const accessToken =
      'pk.eyJ1Ijoia2VsdmluNDAxIiwiYSI6ImNrZ3NsczIwejAwaXkycW8wMzlydzVramgifQ.VAmIskjRXkkPzqK3Ob2Npg'; // Substitua pelo seu token de acesso do Mapbox
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}&types=place`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Erro ao fazer geocodificação: ${response.status}`);
      }

      const data = await response.json();

      // A cidade geralmente estará no primeiro resultado com o tipo "place"
      const place = data.features.find((feature: any) =>
        feature.place_type.includes('place'));

      if (place) {
        return place.text; // Retorna o nome da cidade
      }
      throw new Error('Cidade não encontrada nas coordenadas fornecidas.');
    } catch (error) {
      console.error('Erro ao obter a cidade a partir das coordenadas:', error);
      return 'Cidade Desconhecida';
    }
  }

  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  function closeRegisterModal() {
    setIsRegisterModalOpen(!isRegisterModalOpen);
  }

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  function openFilterModal() {
    setIsFilterModalOpen(!isFilterModalOpen);
  }

  function closeFilterModal() {
    setIsFilterModalOpen(!isFilterModalOpen);
  }

  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);

  function openOrderModal() {
    setIsOrderModalOpen(!isOrderModalOpen);
  }

  function closeOrderModal() {
    setIsOrderModalOpen(!isOrderModalOpen);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const price = formData.get('cost') as string;
    const gender = formData.get('genre') as string;
    const profissional = formData.get('profissional') as string;

    // Atualiza os filtros na URL
    const currentFilters = new URLSearchParams(location.search);
    currentFilters.set('cost', price);
    currentFilters.set('genre', gender);
    currentFilters.set('profissional', profissional);

    // Atualiza a URL com os novos parâmetros
    history.push(`/?${currentFilters.toString()}`);

    // Chama a função para buscar os profissionais com base nos novos filtros
    fetchProfessionals(price, gender, profissional);
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const handleOrder = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const order = formData.get('order') as string;
    fetchProfessionals(order);
    setIsOrderModalOpen(!isOrderModalOpen);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filterFromUrl = queryParams.get('profissional') || '';
    const costFromUrl = queryParams.get('cost') || '';
    const genreFromUrl = queryParams.get('genre') || '';

    setSelectedFilter(filterFromUrl);
    fetchProfessionals(costFromUrl, genreFromUrl);
  }, [location.search]);

  const [selectedOrder, setSelectedOrder] = useState<string>('');

  const handleOrderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOrder(event.target.value);
  };

  const sortProfessionals = (
    professionals: ProfessionalWithDistance[],
    order: string,
  ) => {
    // Função auxiliar para ordenar por presença de foto
    const sortByPhoto = (
      a: ProfessionalWithDistance,
      b: ProfessionalWithDistance,
    ) => {
      if (a.avatar_url && !b.avatar_url) return -1;
      if (!a.avatar_url && b.avatar_url) return 1;
      return 0; // Se ambos têm ou não têm foto, continuam iguais
    };

    switch (order) {
      case 'menor-preco':
        return professionals
          .sort((a, b) => parseCost(a.cost) - parseCost(b.cost)) // Ordena por menor preço
          .sort(sortByPhoto); // Dentro do critério de preço, ordena por foto
      case 'maior-preco':
        return professionals
          .sort((a, b) => parseCost(b.cost) - parseCost(a.cost)) // Ordena por maior preço
          .sort(sortByPhoto); // Dentro do critério de preço, ordena por foto
      case 'menor-distancia':
        return professionals
          .sort((a, b) => (a.distance ?? Infinity) - (b.distance ?? Infinity)) // Ordena por menor distância
          .sort(sortByPhoto); // Dentro do critério de distância, ordena por foto
      default:
        return professionals;
    }
  };

  const sortedProfessionals = sortProfessionals(
    filteredProfessionals,
    selectedOrder,
  );

  const countAppliedFilters = () => {
    const currentFilters = new URLSearchParams(location.search);

    // Filtros que deseja contar
    const filtersToCheck = ['cost', 'genre', 'profissional'];

    // Conta os filtros que têm um valor definido (não vazio)
    const appliedFilters = filtersToCheck.filter(filter => {
      const value = currentFilters.get(filter);
      return value !== null && value.trim() !== ''; // Verifica se o filtro existe e não está vazio
    });

    return appliedFilters.length; // Retorna o número de filtros aplicados
  };

  const countFilters = countAppliedFilters();

  function adicionarReticencias(texto: string) {
    const limite = 18;
    if (texto.length > limite) {
      return `${texto.slice(0, limite)}...`;
    }
    return texto;
  }

  const handleRegisterForm = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const data = {
      name: formData.get('name'),
      whatsapp: formData.get('whatsapp'),
      email: formData.get('email'),
      entity: formData.get('entity'),
      authorization: formData.get('permissionContact'),
    };

    localStorage.setItem('@Cuid:contratante', JSON.stringify(data));

    await api.post('/employers', {
      name: data.name,
      email: data.email,
      whatsapp: data.whatsapp,
      entity: data.entity,
      city: cidadePesquisa,
      authorization: !!data.authorization,
    });

    fetch('https://ntfy.sh/202211_NOTIFICATIONS_ABOUT_CUID', {
      method: 'POST',
      body: `CADASTRO CONTRATANTE: NOME: ${data.name}, WHATSAPP: ${data.whatsapp}, CIDADE: ${cidadePesquisa}`,
      headers: {
        Priority: '2',
        Tags: 'loudspeaker',
      },
    });
    const caregiverPage = localStorage.getItem('@Cuid:page');
    window.location.href = `https://app.cuid.com.br/me/${caregiverPage}`;
  };

  function saveEmployerInSession(caregiverSlug: string) {
    localStorage.setItem('@Cuid:page', `${caregiverSlug}`);
    localStorage.setItem('@Cuid:city', cidadePesquisa);
    history.push('/cadastro/contratante');
  }

  function navigateToProfile(caregiverSlug: string) {
    localStorage.setItem('@Cuid:page', `${caregiverSlug}`);

    const existsAccount = localStorage.getItem('@Cuid:contratante');
    if (!existsAccount) {
      setIsRegisterModalOpen(true);
    } else {
      setIsRegisterModalOpen(false);
      window.location.href = `https://app.cuid.com.br/me/${caregiverSlug}`;
    }
  }

  function navigateToWhatsapp(
    caregiverSlug: string,
    name: string,
    occupation: string,
    city: string,
    uf: string,
  ) {
    const existsAccount = localStorage.getItem('@Cuid:contratante');
    if (!existsAccount) {
      saveEmployerInSession(caregiverSlug);
    } else {
      window.open(
        `https://wa.me/5511959984119?text=Ol%C3%A1!%0A%0AGostaria%20de%20mais%20informa%C3%A7%C3%B5es%20referente%20ao%20profissional%20abaixo:%0A%0ANome%20do%20Profissional: ${
          name.split(' ')[0]
        }%0ACargo: ${occupation}%0ACidade: ${city}%0AUF: ${uf}%0ALink do perfil: http://app.cuid.com.br/me/${caregiverSlug}`,
      );
    }
  }

  return (
    <S.Container>
      <header>
        <a href="/">
          <img
            src="https://cuid.com.br/assets/images/CUID%20CARE3.png"
            alt="Logo da Cuid"
          />
        </a>
        <div className="address-container">
          <SearchBox map={mapRef.current!} onSearch={handleSearch} />
          <a href="#" onClick={getUserLocation}>
            <strong>usar minha localização</strong>
          </a>
        </div>
        <nav>
          <ul>
            <li>
              <a href="https://cuid.com.br">Início</a>
            </li>
            <li>
              <a href="https://app.cuid.com.br/login">Entrar</a>
            </li>
            <li>
              <a href="https://cuid.com.br/profissional.html">Cadastre-se</a>
            </li>
          </ul>
        </nav>
      </header>

      <header id="mobile">
        <div>
          <a href="/">
            <img
              src="https://cuid.com.br/assets/images/CUID%20CARE3.png"
              alt="Logo da Cuid"
            />
          </a>
          <button onClick={openFilterModal} id="btn-menu">
            <FiMenu className="icon" />
          </button>
        </div>
        <div className="mobile-input">
          <SearchBox map={mapRef.current!} onSearch={handleSearch} />
        </div>
      </header>
      <S.Separator />

      {isRegisterModalOpen && (
        <S.ModalContainer>
          <S.ModalContentRegister>
            <S.ModalHeader>
              <div />
              <div>
                <h3>Criar conta</h3>
                <br />
                <p>
                  Crie sua conta e tenha acesso a mais de 860 profissionais em
                  todo o Brasil.
                </p>
              </div>
              <div>
                <FiX onClick={closeRegisterModal} />
              </div>
            </S.ModalHeader>
            <S.Separator />

            <S.FilterOptions>
              <form onSubmit={handleRegisterForm}>
                <div>
                  {/* <div id="filter-options-mobile"> */}
                  <div className="filter-container register">
                    <strong>Nome</strong>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="ex: João Pedro"
                      required
                    />
                  </div>
                  <S.Separator />
                  {/* </div> */}
                  <div className="filter-container register">
                    <strong>Whatsapp</strong>
                    <input
                      name="whatsapp"
                      id="whatsapp"
                      type="tel"
                      placeholder="ex: (11) 99999-9999"
                      maxLength={15}
                      required
                    />
                  </div>
                  <S.Separator />
                  <div className="filter-container register">
                    <strong>Email</strong>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="ex: seu@email.com.br"
                      required
                    />
                  </div>
                  <S.Separator />
                  <div className="filter-container register">
                    <strong>Qual é o seu perfil?</strong>
                    <select name="entity" id="entity" required>
                      <option value="">Selecionar perfil</option>
                      <option value="Familia">Sou Familiar</option>
                      <option value="Empresa">Sou Empresa</option>
                    </select>
                  </div>
                </div>

                <S.Separator />
                <div className="filter-container">
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      name="permissionContact"
                      id="permissionContact"
                    />
                    <span>
                      Autorizo a Cuid a entrar em contato comigo para me
                      auxiliar na busca por um profissional.
                    </span>
                  </div>
                </div>
                <S.Separator />
                <div className="filter-container">
                  <button type="submit">Enviar</button>
                </div>
              </form>
            </S.FilterOptions>
          </S.ModalContentRegister>
        </S.ModalContainer>
      )}

      {isFilterModalOpen && (
        <S.ModalContainer>
          <S.ModalContent>
            <S.ModalHeader>
              <div />
              <div>
                <strong>Filtros</strong>
              </div>
              <div>
                <FiX onClick={closeFilterModal} />
              </div>
            </S.ModalHeader>
            <S.Separator />

            <S.FilterOptions>
              <form onSubmit={handleSubmit}>
                <div>
                  <div id="filter-options-mobile">
                    <div className="filter-container">
                      <strong>Profissional</strong>
                      <select name="profissional" id="profissional">
                        <option value="">Selecione uma opção</option>
                        <option value="cuidador de idosos">
                          Cuidadores de idosos
                        </option>
                        <option value="auxiliar de enfermagem">
                          Aux. de Enfermagem
                        </option>
                        <option value="técnico de enfermagem">
                          Téc. de Enfermagem
                        </option>
                        <option value="babá">Babás</option>
                      </select>
                    </div>
                    <S.Separator />
                  </div>
                  <div className="filter-container">
                    <strong>Faixa de preço</strong>
                    <select name="cost" id="cost">
                      <option value="">Selecione uma opção</option>
                      <option value="12000">R$ 120,00</option>
                      <option value="16000">R$ 160,00</option>
                      <option value="18000">R$ 180,00</option>
                      <option value="20000">R$ 200,00 ou mais</option>
                    </select>
                  </div>
                  <S.Separator />
                  <div className="filter-container">
                    <strong>Gênero</strong>
                    <select name="genre" id="genre">
                      <option value="">Selecione uma opção</option>
                      <option value="Feminino">Feminino</option>
                      <option value="Masculino">Masculino</option>
                    </select>
                  </div>
                </div>

                <S.Separator />
                <div className="filter-container">
                  <a href="/">Limpar filtros</a>
                  <button type="submit">Buscar</button>
                </div>
              </form>
            </S.FilterOptions>
          </S.ModalContent>
        </S.ModalContainer>
      )}

      {isOrderModalOpen && (
        <S.ModalContainer>
          <S.ModalContent>
            <S.ModalHeader>
              <div />
              <div />
              <div>
                <FiX onClick={closeOrderModal} />
              </div>
            </S.ModalHeader>
            <S.Separator />

            <S.FilterOptions>
              <form onChange={handleOrder}>
                <div>
                  <div className="filter-container">
                    <strong>Ordernar por</strong>
                    <select
                      name="order"
                      id="order"
                      onChange={handleOrderChange}
                    >
                      <option value="">Escolha um valor</option>
                      <option value="menor-preco">Menor preço</option>
                      <option value="maior-preco">Maior preço</option>
                      <option value="menor-distancia">Menor distância</option>
                    </select>
                  </div>
                  <S.Separator />
                </div>
                <S.Separator />
                <div className="filter-container">
                  <button type="submit">Ordenar</button>
                </div>
              </form>
            </S.FilterOptions>
          </S.ModalContent>
        </S.ModalContainer>
      )}

      <S.Content>
        <S.Filters>
          <button onClick={openFilterModal}>
            <FiSliders className="icon" />
            Filtros {countFilters ? <span> {countFilters} </span> : ''}
          </button>
          <div className="button-filter">
            {filtersOptions.map(filter => (
              <button
                key={filter.value}
                className={selectedFilter === filter.value ? 'active' : ''}
                onClick={() => handleFilterClick(filter.value)}
              >
                {filter.name}
              </button>
            ))}
          </div>
          <button onClick={openOrderModal}>
            <FiLayers className="icon" />
            Ordenar busca
          </button>
        </S.Filters>

        <S.Separator />

        <div>
          <S.Sidebar>
            <strong className="qdy-professionals">
              {isPendingFetch
                ? 'Buscando profissionais...'
                : `Encontramos ${filteredProfessionals.length} profissionais`}
            </strong>

            <S.CardContainer>
              {filteredProfessionals.map(professional => (
                <S.Card
                  key={professional.id}
                  style={{
                    cursor: 'pointer',
                    border:
                      selectedProfessional === professional
                        ? '1px solid #15803d'
                        : '1px solid #ddd',
                  }}
                >
                  <div className="personal-img">
                    {professional.avatar_url ? (
                      <img
                        src={professional.avatar_url}
                        alt={`Foto de ${professional.name}`}
                      />
                    ) : (
                      <img
                        src="https://app-cuid.s3.amazonaws.com/no-avatar.png"
                        alt="perfil sem foto"
                      />
                    )}
                  </div>
                  <div>
                    <span>{professional.occupation}</span>
                    <span className="professional-name">
                      {professional.name.charAt(0).toUpperCase() +
                        professional.name.slice(1).toLowerCase().split(' ')[0]}
                    </span>
                  </div>
                  <div>
                    {adicionarReticencias(professional.neighborhood)} -{' '}
                    {professional.uf}
                  </div>
                  <div>
                    Plantões a partir de{' '}
                    {parseCost(professional.cost).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </div>
                  {currentLocation && professional.distance && (
                    <div>
                      Distância:{' '}
                      {professional.distance < 1
                        ? `${(professional.distance * 1000).toFixed(0)} m`
                        : `${professional.distance.toFixed(2)} km`}
                    </div>
                  )}
                  <button
                    className="to-profile"
                    onClick={() => navigateToProfile(professional.slug)}
                  >
                    Ver perfil
                  </button>

                  <button
                    className="to-profile see-on-map"
                    onClick={() => {
                      handleCardClick(professional); // Chama a função normalmente
                    }}
                  >
                    Ver no mapa
                  </button>
                </S.Card>
              ))}
            </S.CardContainer>
            <div className="tab-bar">
              <ul>
                <li>
                  <a href="https://cuid.com.br/">Inicio</a>
                </li>
                <li>
                  <a href="https://app.cuid.com.br/login">Entrar</a>
                </li>
                <li>
                  <a href="https://app.cuid.com.br/profissionais/cadastro">
                    Criar conta
                  </a>
                </li>
              </ul>
            </div>
          </S.Sidebar>

          <S.Map>
            <div
              ref={mapContainerRef}
              style={{ width: '40%', height: '85vh', position: 'absolute' }}
              className="mapa"
            />
          </S.Map>
        </div>
      </S.Content>
    </S.Container>
  );
}
