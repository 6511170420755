import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperContent = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    border-radius: 1rem;
    max-width: 600px;
    width: 100%;
    padding: 1.8rem;
    margin: 0 3.2rem;
    position: relative;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      strong {
        color: ${theme.colors['blue-shade-700']};
        font-size: 1.6rem;
      }

      button {
        font-size: 0;
        padding: 1rem;
        border: none;
        background: transparent;

        position: absolute;
        top: 0;
        right: 0;

        svg {
          width: 3.2rem;
          height: 3.2rem;
          color: ${theme.colors['shade-300']};
        }

        &:hover svg {
          filter: brightness(0.7);
        }
      }
    }

    section {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      scroll-padding-left: 10%;
      overflow-x: auto;
      padding: 3.2rem 0;

      div {
        text-align: center;

        & + div {
          margin-left: 1.8rem;
        }

        svg {
          width: 2.5rem;
          height: 6rem;
          border-radius: 50%;

          transition: filter 0.2s;

          &:hover {
            filter: brightness(0.7);
          }
        }

        span {
          display: block;
          color: ${theme.colors['shade-900']};
          font-size: 1.3.2rem;
        }
      }
    }

    footer {
      width: 100%;
      margin-top: 3.2rem;
      padding-top: 3.2rem;
      border-top: 1px solid ${theme.colors['shade-300']};

      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid ${theme.colors['shade-150']};
        background: transparent;

        padding: 1rem;
        font-weight: 500;
        color: ${theme.colors['blue-light-900']};

        span {
          color: ${theme.colors['blue-shade-300']};

          max-width: 70%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  `}
`;
