import styled, { css } from 'styled-components';
import Button from '../../components/Button';

export const Container = styled.div`
  flex: 1;
`;

export const Main = styled.main`
  display: flex;
`;

export const Content = styled.div`
  max-width: 740px;
  margin: 6.2rem auto;
  padding: 0 3.2rem;
  height: calc(100% - 12.5rem);

  @media(max-width: 420px) {
    margin: 3.2rem auto;
  }
`;

export const Header = styled.div`
  ${({ theme }) => css`
    height: 12.5rem;
    background: ${theme.colors['shade-900']};
    box-shadow: 0 0.3rem 0.6rem rgba(0,0,0,0.16), 0 0.3rem 0.6rem rgba(0,0,0,0.23);
    display: flex;
    align-items: center;
  `}
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  padding: 0 3.2rem;
  display: flex;
  align-items: center;

  > img {
    width: 11rem;
    margin-right: 8rem;
  }

  @media(max-width: 500px) {
    > img {
      display: none;
    }
  }
`;

export const Profile = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    img {
      width: 5.6rem;
      height: 5.6rem;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 1.8rem;
    }

    div {
      display: flex;
      flex-direction: column;

      span {
        color: #1c3438;
      }

      a {
        strong {
          display: block;
          color: #1c3438;
        }

        span:last-child {
          display: block;
          margin-top: 0.8rem;
          font-size: 1.3.2rem;

          transition: filter 0.1s;

          &:hover {
            filter: brightness(0.8);
          }
        }
      }
    }
  `}
`;

export const SignOutButton = styled.button`
  ${({ theme }) => css`
    background: transparent;
    border: none;
    /* padding: 3rem 0 3rem 2.5rem; */
    width: 13rem;
    height: 9rem;
    margin-left: auto;
    cursor: pointer;

    color: #1c3438;
    font-size: 1.8rem;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    transition: filter 0.1s;

    svg {
      color: #1c3438;
      width: 3.2rem;
      height: 3.2rem;
      margin-right: 1rem;
    }

    &:hover {
      filter: opacity(0.8);
    }
  `}
`;

const sectionStyle = css`
  ${({ theme }) => css`
    width: 100%;
    margin: 5rem 0;
    padding: 5rem 8rem;
    border-radius: 5px;
    border: 1px solid ${theme.colors['blue-50']};
    background: ${theme.colors.white};

    @media(max-width: 500px) {
      margin: 3.2rem 0;
      padding: 3.2rem 2.5rem;
    }

    @media(max-width: 420px) {
      margin: 1.8rem 0;
      padding: 1.8rem 3.2rem;
    }
  `}
`;

const sectionInformationStyle = css`
  ${({ theme }) => css`
    strong {
      display: block;

      font-size: 3.2rem;
      font-weight: 400;
      line-height: 2.5rem;
      color: #1c3438;

      border-bottom: 1px solid ${theme.colors['blue-50']};
      margin-bottom: 2.5rem;
      padding-bottom: 2.5rem;
    }

    span {
      display: block;
      color: ${theme.colors['blue-shade-200']};
      margin-bottom: 0.8rem;
      line-height: 2.5rem;
    }

    div {
      color: #1c3438;
      & + div {
        margin-top: 2.5rem;
      }
    }

    @media(max-width: 500px) {
      strong {
        font-size: 2.8rem;
      }
    }

    @media(max-width: 420px) {
      strong {
        font-size: 3.2rem;
      }
    }
  `}
`;

export const SectionBanner = styled.section`
  ${sectionStyle};
  p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    text-align: center;
    color: #1c3438;
    flex: 1;
  }
`;

export const VerifiedDocs = styled.section`
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1c3438;
    font-size: 1.5rem;

      svg {
      width: 5rem;
      height: 5rem;
      margin: auto 1.5rem;
    }
  }

  .verified {
      svg {
      width: 2rem;
      height: 2rem;
      margin: auto 1.5rem;
      color: green;
    }
    }

  ${sectionStyle};
    p {
      line-height: 2.5rem;
      flex: 1;
    }
`;

export const ContactButton = styled.button`
  ${({ theme }) => css`
  margin: 4rem auto 0;
  border: none;
  border-radius: 5rem;
  background: transparent;
  color: ${theme.colors['blue-shade-400']};
  display: flex;
  align-items: center;
  gap: 1rem;
  svg {
    width: 2rem;
    height: 2rem;
  }

`}
`;

export const PaymentButton = styled(Button)`
  margin: 1rem;

  p{

    color: #fff;
  }
`;

export const SectionAvatar = styled.section`
  ${sectionStyle};

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const PersonalInformation = styled.section`
  ${sectionStyle};
  ${sectionInformationStyle};

  p {
    display: flex;
    flex: 1;
  }
`;

export const ProfessionalInformation = styled.section`
  ${sectionStyle};
  ${sectionInformationStyle};
`;

export const SupportButton = styled.div`
  ${({ theme }) => css`
    z-index: 10;
    position: fixed;
    right: 6rem;
    bottom: 3rem;

    cursor: pointer;

    height: 10rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;



    span {
      position: absolute;
      background-color: ${theme.colors['green-910']};
      color: #fff;
      border-radius: 0.6rem;
      padding: 0.3rem 1rem;
      z-index: 1;
      top: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);

      &::after {
        position: absolute;
        content: "";
        left: 50%;
        bottom: -10px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${theme.colors['green-910']} transparent transparent transparent;
        visibility: visible;
      }

      @media(max-width: 420px) {
        font-size: 1.3.2rem;
        font-weight: 500;
      }
    }

    a {
      margin-top: 2.5rem;
      padding: 0.8rem;
      border-radius: 50%;
      background: ${theme.colors['green-910']};

      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);

      display: flex;
      align-items: center;
      justify-content: center;

      transition: filter 0.1s;

      svg {
        width: 2.5rem;
        height: 2.5rem;
        color: ${theme.colors.white};
      }

      &:hover {
        filter: brightness(0.8);
      }


      @media(max-width: 420px) {
        margin-top: 3rem;

        svg {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    @media(max-width: 600px) {
      & {
        right: 3rem;
        bottom: 3rem;
      }
    }

    @media(max-width: 420px) {
      right: 3rem;
      bottom: 0.5rem;
    }
  `}
`;

export const Biography = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 15px;
  margin-top: 10px;

  a {
    text-decoration: none;
    span {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    color: #ee4644;

    &:hover {
      opacity: .8;
    }
  }
  }

  > img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  div {
    margin-left: 16px;
    line-height: 24px;
  }
`;

export const About = styled.div`
  margin-top: 64px;

  strong {
    font-size: 16px;
    font-weight: 400;
  }

  div {
    background: #fff;
    font-size: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 16px 24px;
    border-radius: 10px;
    margin: 12px;

  }

  p {
    margin-left: 20px;
  }

`;

export const ShareProfile = styled.div`
  ${({ theme }) => css`
  z-index: 10;
    position: fixed;
    right: 6rem;
    bottom: 18rem;

    cursor: pointer;

    height: 10rem;
    display: flex;
    align-items: center;
    flex-direction: column;



    span {
      position: absolute;
      text-align: center;
      background-color: ${theme.colors['blue-shade-400']};
      color: ${theme.colors.white};
      border-radius: 0.6rem;
      padding: 0.3rem 1rem;
      z-index: 1;
      top: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);

      &::after {
        position: absolute;
        content: "";
        left: 50%;
        bottom: -10px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${theme.colors['blue-shade-400']} transparent transparent transparent;
        visibility: visible;
      }

      @media(max-width: 420px) {
        font-size: 1.3.2rem;
        font-weight: 500;
      }
    }

    button {
      background: ${theme.colors['blue-shade-400']};
      color: ${theme.colors.white};
      border: none;
      margin-top: 6rem;
      padding: 0.8rem;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);

      svg {
        width: 2.5rem;
        height: 2.5rem;
        margin-left: -0.1rem;
        color: ${theme.colors.white};
      }

      @media(max-width: 420px) {
        margin-top: 4.5rem;

        svg {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    @media(max-width: 600px) {
      & {
        right: 3rem;
        /* bottom: 3rem; */
      }
    }

    @media(max-width: 420px) {
      right: 3rem;
      bottom: 10rem;
    }
  `}
`;
