import { FormEvent } from 'react';

export function cepMask(
  event: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> {
  let { value } = event.currentTarget;

  value = value.replace(/\D/gi, '');
  value = value.replace(/^(\d{5})(\d{3})/g, '$1-$2');

  // eslint-disable-next-line no-param-reassign
  event.currentTarget.value = value;

  return event;
}


export function phoneMask(
  event: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> {
  let { value } = event.currentTarget;

  value = value.replace(/\D/gi, '');
  value = value.replace(/^(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3');

  // eslint-disable-next-line no-param-reassign
  event.currentTarget.value = value;

  return event;
}

export function cpfMask(
  event: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> {
  let { value } = event.currentTarget;

  value = value.replace(/\D/gi, '');
  value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');

  // eslint-disable-next-line no-param-reassign
  event.currentTarget.value = value;

  return event;
}

