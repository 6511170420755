import styled, { css } from 'styled-components';

export const Container = styled.header`
  ${({ theme }) => css`
    padding: 0.2rem 0;
    background: ${theme.colors.white};
    box-shadow: 0 2px 9px 0 rgb(158 184 209 / 41%);
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 3.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 11rem;
    }

    a, button {
      display: flex;
      align-items: center;
      background: transparent;
      color: ${theme.colors['green-900']};
      font-size: 1.8rem;

      padding: 3rem 0 3rem 3.2rem;

      transition: filter 0.2s;
    }

    svg {
      margin-right: 1rem;
      width: 2.5rem;
      height: 2.5rem;
    }

    a:hover,
    button:hover {
      filter: brightness(0.8);
    }
  `}
`;

