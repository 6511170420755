const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? '#1c3438' : '#333',
    backgroundColor: state.isSelected ? '#d3e2e5' : '#fff',
  }),
  control: (provided: any) => ({
    ...provided,
    boxShadow: null,
    minHeight: '5rem',
    fontSize: '1.6rem',
    fontFamily: 'Poppins',
    borderWidth: 0,
    borderRadius: '0.5rem',
    backgroundColor: '#f5f8fa',
    color: '#1c3438',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    width: state.selectProps.width,
    color: '#1c3438',
    padding: 20,
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: '#1c3438',
    padding: '1.8rem',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: !state.hasValue ? 'transparent' : 'transparent',
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    width: '100%',

  }),
  input: (provided: any, state: any) => ({
    ...provided,
    height: '5rem',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
  }),
  default: (provided: any, state: any) => ({
    ...provided,

    color: state.isFocused ? '#1c3438' : '',
  }),
};

export default customStyles;
