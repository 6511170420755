import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
  isFocused: boolean;
  isFilled: boolean;
  isValid: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, isFocused, isFilled, isErrored, isValid }) => css`
    position: relative;
    margin: 0.8rem 0;
    border-radius: 0.8rem;
    width: 100%;

    & + div {
      margin: 1.8rem 0;
    }

    ${isErrored &&
    css`
      border-color: ${theme.colors['red-800']};

      span {
        visibility: visible;
      }
    `}

    ${isFilled &&
    css`
      border-color: ${theme.colors['green-600']};
    `}

    ${isFocused &&
    css`
      border-color: ${theme.colors['green-600']};
      color: ${theme.colors['green-900']};
    `}

    input {
      width: 100%;
      padding: 0 1rem;
      height: 5.6rem;
      font-family: 'Poppins', sans-serif;
      font-size: 1.8rem;
      color: ${theme.colors['green-900']};
      background: #ffffff;
      border-radius: 0.8rem;
      border: 1px solid rgba(0,0,0, .1);
      outline: 0;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
        -webkit-text-fill-color: ${theme.colors['green-900']};
      }

      &:read-only {
        color: ${theme.colors['green-900']};
      }

      ${isFilled &&
    css`
          color: ${theme.colors['blue-shade-400']};
          border-color: ${theme.colors['green-600']};
        `}

        @media (max-width: 550px) {
          height: 5rem;
          font-size: 1.2.5rem;
        }
    }

    span {
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;

      font-size: 1.2.5rem;
      color: ${theme.colors['red-800']};

      ${isValid &&
    css`
        color: ${theme.colors['green-600']};
      `}
    }

    &:focus-within::after {
      content: '';
      position: absolute;
      left: 1.8rem;
      right: 1.8rem;
      width: calc(100% - 3.2rem);
      bottom: 0;
      height: 3.2rem;
    }

    @media (max-width: 720px) {
      .input-block input {
        width: 100%;
      }
    }
  `}
`;
