export function mask(value: number | string, pattern: string) {
  let i = 0;
  const v = value.toString();

  return pattern.replace(/#/g, () => v[i++] || '');
}


export function formatCurrency(value: number | string) {

  value = String(value).replace(/\D/g, '');

  value = Number(value) / 100

  value = value.toLocaleString('pt-BR', {
    style: "currency",
    currency: 'BRL'
  })

  return value;
}

export function cpfMask(
  value: string,
): string {
  let cpf;

  cpf = value.replace(/\D/gi, '');
  cpf = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');

  return cpf;
}

export function phoneMask(
  value: string,
): string {
  let phone;

  phone = value.replace(/\D/gi, '');
  phone = value.replace(/^(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3');

  return phone;
  ;
}


export function cepMask(
  value: string,
): string {
  let cep;

  cep = value.replace(/\D/gi, '');
  cep = value.replace(/(\d{5})(\d{3})/g, '$1-$2');

  return cep;
}

// 00/00
export function expiresCardMask(
  value: string,
): string {

  let expiresCard;
  expiresCard = value.replace(/\D/g, '')
  expiresCard = value.replace(/(\d{2})(\d)/, "$1/$2")
  expiresCard = value.replace(/(\d{2})(\d)/, "$1/$2")
  expiresCard = value.replace(/(\d{2})(\d)/, "$1/$2")

  return expiresCard;
}

// 0000 0000 0000 0000
export function creditCardMask(
  value: string,
): string {

  let creditCard;
  creditCard = value.replace(/\D/g, '')
  creditCard = value.replace(/(\d{2})(\d)/, "$1/' '/$2")
  creditCard = value.replace(/(\d{2})(\d)/, "$1 $2")
  creditCard = value.replace(/(\d{4})(\d)/, "$1 $2")

  return creditCard;
}
