import styled, { css } from 'styled-components';
import Button from '../../components/Button';

export const Container = styled.div``;

export const Content = styled.main`
  ${({ theme }) => css`
  flex: 1;
  margin: 0 auto;
  padding: 0 3.2rem;

  form {
    max-width: 700px;
    margin: 6.2rem auto;

    display: flex;
    flex-direction: column;

    background: ${theme.colors.white};
    border: 1px solid ${theme.colors['blue-50']};
    border-radius: 0.5rem;

    padding: 5rem 8rem;

    overflow: hidden;

    fieldset {
      border: 0;

      & + fieldset {
        margin-top: 8rem;
      }

      legend {
        width: 100%;

        font-size: 3.2rem;
        line-height: 2.5rem;
        font-weight: 400;
        color: ${theme.colors['blue-shade-400']};

        border-bottom: 1px solid ${theme.colors['blue-50']};
        margin-bottom: 2.5rem;
        padding-bottom: 2.5rem;
      }

      input {
        border: .1rem solid rgb(231, 229, 229);
      }
    }

    label {
      display: flex;
      align-items: baseline;
      color: ${theme.colors['blue-shade-200']};
      margin: 2.5rem 0 0.8rem;
      line-height: 2.5rem;

      span {
        font-size: 1.2.5rem;
        color: #8fa7b3;
        margin-left: 2.5rem;
        line-height: 2.5rem;
      }
    }

    @media (max-width: 600px) {
      padding: 3.2rem 2.5rem;
    }

    @media (max-width: 420px) {
      padding: 1.8rem 3.2rem;

      fieldset {
      border: 0;

      & + fieldset {
        margin-top: 2.5rem;
      }

      legend {
          width: 100%;

          font-size: 3.2rem;
          line-height: 2.8rem;
          margin-bottom: 3.2rem;
          padding-bottom: 1.3.2rem;
        }
      }

      label {
        margin: 3.2rem 0 0.6rem;
        line-height: 2.5rem;

        span {
          font-size: 1.2.5rem;
          color: #8fa7b3;
          margin-left: 3.2rem;
          line-height: 3.2rem;
        }
      }
    }
  }
  `}
`;

export const WrapperToggleButton = styled.div`
  width: 100%;
  display: flex;

  button {
    width: 100%;
    height: 5rem;
    background: #f5f8fa;
    border: 6.2rem auto;
    color: #1c3438;
    cursor: pointer;

    &.active {
      background: #edfff6;
      border: 1px solid #a1e9c5;
      color: #37c77f;
    }

    &:first-child {
      border-radius: 0.5rem 0 0 0.5rem;
    }

    &:last-child {
      border-radius: 0 0.5rem 0.5rem 0;
      border-left: 0;
    }
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 2.5rem;
`;

