import React, { createContext, ReactNode, useContext, useState } from 'react';
import { SocialShare } from '../components/SocialShare';

type ShareContextProviderProps = {
  children: ReactNode;
};

type ShareContextData = {
  shareURL: string;
  open: boolean;
  handleOpenSocialShare: (slug: string) => void;
  toggleSocialShare: (slug: string, title: string) => void;
  handleCloseSocialShare: () => void;
};

const SocialShareContext = createContext({} as ShareContextData);

export function SocialShareProvider({
  children,
}: ShareContextProviderProps): JSX.Element {
  const [shareURL, setShareURL] = useState('');
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');

  function handleOpenSocialShare(slug: string) {
    setOpen(true);
    setShareURL(slug);
  }

  function handleCloseSocialShare() {
    setOpen(false);
  }

  function toggleSocialShare(slug: string, title: string) {
    setOpen(prevState => !prevState);
    setShareURL(slug);
    setTitle(title);
  }

  return (
    <SocialShareContext.Provider
      value={{
        open,
        shareURL,
        handleOpenSocialShare,
        toggleSocialShare,
        handleCloseSocialShare,
      }}
    >
      {children}
      {open && <SocialShare title={title} />}
    </SocialShareContext.Provider>
  );
}

export const useSocialShare = () => useContext(SocialShareContext);
