import React, { useState } from 'react';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi';

import * as S from './styles';
import { theme } from '../../styles/theme';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    color: '#000000',
    borderRadius: '0',
    width: '100%',
    maxWidth: '100rem',
    border: 'none',
    padding: '0',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    paddingLeft: '4px',
    paddingRight: '4px',
    backdropFilter: 'blur(8px)',
  },
};

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

export default function ContactModal({ isOpen, onClose }: ModalProps) {
  const [selectOption, setSelectOption] = useState('');

  const profileUrl = window.location.href;

  return (
    <Modal
      isOpen={isOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <S.Container>
        <S.CloseButton type="button" onClick={() => onClose()}>
          <FiX />
          soefiwoeifjwoefij
        </S.CloseButton>

        <form >
          <input //cuidador
            id="url"
            type="hidden"
            name="url"
            value={profileUrl}
          />


          <input
            id="name"
            name="name"
            placeholder='Nome'
            required
          />

          <input
            id="whatsapp"
            type="tel"
            name="whatsapp"
            placeholder='Whatsapp'
            required
          />


          <input
            id="email"
            type="email"
            name="email"
            placeholder='E-mail'
            required
          />


          <select
            value={selectOption}
            onChange={event => setSelectOption(event.target.value)}
          >
            <option
              value=''
              disabled
              selected
              style={{ color: theme.colors['shade-300'] }}
            >Qual motivo?</option>
            <option>Nudez</option>
            <option>Promove incitação ao ódio, atividades violentas ou ilegais/ofensivas</option>
            <option>Spam, malware ou "phishing" (login falso)</option>
            <option>Violação de direitos autorais</option>
            <option>Profissional não responde</option>
          </select>

          <input //Assunto
            id="assunto"
            type="hidden"
            name="assunto"
            value={selectOption}
          />


          <textarea
            id="message"
            name="message"
            placeholder='Mensagem'
            required
          />


          <S.CheckBoxWrapper>
            <input type="checkbox" name="autoriza-whatsapp" id="whatsapp" value='true' />

            <label htmlFor='checkbox'>Autorizo a Cuid a entrar em contato comigo por whatsapp para solução do caso.</label>

          </S.CheckBoxWrapper>

          <S.SubmitButton type="submit" >
            Enviar
          </S.SubmitButton>
        </form>
      </S.Container>
    </Modal>
  );
}
