import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

interface SearchBoxProps {
  map: mapboxgl.Map;
  onSearch: (location: { lat: number; lng: number }) => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ map, onSearch }) => {
  const geocoderRef = useRef<HTMLDivElement | null>(null);
  const geocoderInstanceRef = useRef<MapboxGeocoder | null>(null);
  const [marker, setMarker] = useState<mapboxgl.Marker | null>(null);
  const [searchAddress, setSearchAddress] = useState<string>(''); // Armazena o endereço pesquisado

  useEffect(() => {
    if (geocoderRef.current && map) {
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        placeholder: 'Para onde você precisa de profissionais?',
        mapboxgl: mapboxgl,
        language: 'pt-BR',
        countries: 'BR',
      });

      geocoderInstanceRef.current = geocoder;

      geocoder.on('result', e => {
        const { geometry } = e.result;
        const { coordinates } = geometry;
        const [lng, lat] = coordinates;
        onSearch({ lat, lng });

        // Remove o marcador antigo, se houver
        if (marker) {
          marker.remove();
        }

        // Armazenar o endereço pesquisado
        setSearchAddress(e.result.place_name);

        // Extrair a cidade da resposta
        let city = '';
        if (e.result.context) {
          // Procura pelo objeto que tem o tipo 'locality' (cidade)
          const cityContext = e.result.context.find(
            (context: { id: string | string[] }) =>
              context.id.includes('place'),
          );
          if (cityContext) {
            city = cityContext.text; // A cidade é armazenada aqui
            // Armazenar a cidade no localStorage
            localStorage.setItem('@Cuid:search_city', city);
          }
        }
        // Adiciona um novo marcador para o endereço pesquisado
        const newMarker = new mapboxgl.Marker({ color: '#15803d' })
          .setLngLat([lng, lat])
          .addTo(map);

        setMarker(newMarker);

        // Centraliza o mapa no novo marcador
        map.flyTo({
          center: [lng, lat],
          zoom: 15,
          essential: true,
        });
      });

      map.addControl(geocoder);
      geocoderRef.current.appendChild(geocoder.onAdd(map));

      return () => {
        if (geocoderInstanceRef.current) {
          map.removeControl(geocoderInstanceRef.current);
        }
      };
    }
  }, [map, onSearch, marker]);

  useEffect(() => {
    // Atualiza o valor do input se houver um endereço pesquisado
    if (searchAddress) {
      setTimeout(() => {
        const geocoder = geocoderInstanceRef.current;
        if (geocoder) {
          const inputElement = document.querySelector(
            '.mapboxgl-ctrl-geocoder--input',
          ) as HTMLInputElement;

          if (inputElement) {
            inputElement.value = searchAddress;
            geocoder.setInput(searchAddress);
          }
        }
      }, 100); // Ajuste o tempo se necessário
    }
  }, [searchAddress]);
  return <div ref={geocoderRef} />;
};

export default SearchBox;
