import styled from 'styled-components';

export const Container = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  color: #000000;
  font-family: 'Manrope', sans-serif;

  header {
    width: 100%; /* Alterado de 100vw para 100% */
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 4rem;
    font-size: 1.6rem;

    .address-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
    }

    @media (max-width: 720px) {
      display: none;

      #filter-options-mobile {
        display: block;
      }
    }
  }

  .mapboxgl-ctrl-geocoder {
    min-width: 400px;
  }

  .mapboxgl-ctrl-geocoder input {
    width: 400px;
  }

  header img {
    width: 15rem;
    height: auto;
    object-fit: cover;
  }

  nav ul {
    display: flex;
    gap: 2rem;
  }

  li {
    list-style-type: none;
  }

  #mobile {
    display: none;

    @media (max-width: 720px) {
      display: block;
      padding: 2rem;

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 2rem 0;

        img {
          width: 55%;
          height: auto;
        }
      }

      #btn-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 2rem;
        border-radius: 8px;
        border: 1px solid #e2e8f0;
        cursor: pointer;
        font-size: 2rem;

        span {
          padding: 1rem;
          width: 1rem;
          height: 1rem;
          background-color: #15803d;
          color: #fff;
          font-size: 1.3rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .mobile-input {
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
      }
      @media (max-width: 720px) {
        .mapboxgl-ctrl-geocoder {
          min-width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .mapboxgl-ctrl-geocoder input {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .mapa {
    @media (max-width: 720px) {
      display: none;
    }
  }

  #mobile-filter {
    display: none;

    @media (max-width: 720px) {
      display: block;
    }
  }

  /* popup.css */

  .mapboxgl-popup-content {
    width: 100%;
    border-radius: 10px;
    padding: 1rem 2rem;
  }

  .popup-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    a {
      width: 100%;
      font-weight: bold;
      padding: 1rem;
      text-align: center;
      background: #15803d;
      color: #fff;
    }
  }

  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    border: none;
  }

  .popup-img,
  .no-photo {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .no-photo {
    background: #ececec;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popup-content {
    display: flex;
    flex-direction: column;

    a {
      margin-top: 2rem;
    }
  }

  .popup-content h3 {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  .popup-content p {
    font-size: 14px;
    color: #666;
  }

  @media (max-width: 720px) {
    padding: 0 2rem; /* Ajustado o padding para evitar rolagem */
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid #e2e8f0;

  @media (max-width: 720px) {
    border-bottom: 0px solid #e2e8f0;
    display: none;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
  }

  @media (max-width: 720px) {
    margin-top: 2rem;
  }
`;

export const Filters = styled.div`
  padding: 2rem 4rem;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 2rem;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    cursor: pointer;

    span {
      padding: 1rem;
      width: 1rem;
      height: 1rem;
      background-color: #15803d;
      color: #fff;
      font-size: 1.3rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 720px) {
      display: none;
    }
  }

  .icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  .active {
    background-color: #15803d;
    color: #fff;
  }

  .button-filter {
    display: flex;
    gap: 2rem;

    @media (max-width: 720px) {
      display: none;
    }
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Opção de adicionar uma cor de fundo semi-transparente */
  z-index: 1000; /* Valor maior para garantir que o modal fique no topo */
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  padding: 2rem;
`;

export const ModalContentRegister = styled.div`
  width: 100%;
  max-width: 57rem;
  background: #f9f9f9;
  color: #09090b;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const ModalContent = styled.div`
  width: 100%;
  max-width: 37rem;
  background: #f9f9f9;
  color: #09090b;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const FilterOptions = styled.div`
  > div {
    display: flex;
    align-items: center; /**remover */
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    gap: 1rem;

    strong {
      width: 12rem;
    }

    select {
      border-radius: 8px;
      padding: 1rem;
    }

    a {
      color: #15803d;
      border: 0;
      font-weight: 400;
    }

    .checkbox-container {
      display: flex;
      align-items: start;
      gap: 1rem;
      font-size: 1.6rem;
    }

    button {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      border: 1px solid #e2e8f0;
      cursor: pointer;
      background-color: #15803d;
      color: #fff;

      border-radius: 8px;
      padding: 1rem 2rem;
      width: 100%;
      font-size: 1.8rem;
    }

    @media (max-width: 720px) {
      button {
        height: 7rem;
      }
    }
  }

  .register {
    input,
    select {
      border: 1px solid rgb(211, 226, 229);
      border-radius: 8px;
      padding: 1rem 2rem;
      width: 100%;
      font-size: 1.8rem;
      height: 5.6rem;
      color: #666;
    }
    strong,
    select {
      width: 100%;
    }
    align-items: flex-start;
    flex-direction: column;
  }

  select {
    background: #fff;
  }

  button {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    cursor: pointer;
    background-color: #15803d;
    color: #fff;
  }

  #filter-options-mobile {
    display: none;
  }

  @media (max-width: 720px) {
    #filter-options-mobile {
      display: block;
    }
  }
`;

export const Sidebar = styled.aside`
  width: 100%; /* Alterado de 60% para 100% */
  max-width: 60%; /* Define um limite máximo de largura */
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;

  strong {
    font-size: 2rem;
  }

  .qdy-professionals {
    padding-bottom: 2rem;
  }

  .tab-bar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .qdy-professionals {
      margin-top: 4rem;
    }

    background: inherit;
    padding: 1rem;
    max-width: 100%; /* Define um limite máximo de largura */

    align-items: center;

    .tab-bar {
      width: 100%;
      position: fixed;
      bottom: 0;
      font-size: 2rem;
      background-color: #fff;
      padding: 2rem 1rem;
      border-top: 1px solid rgb(221, 221, 221);
      display: flex;

      ul {
        width: 100%;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  height: 80vh; /* Ajuste para a altura desejada com base na tela */
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  overflow: auto; /* Adiciona uma barra de rolagem quando necessário */
  gap: 1rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

export const Card = styled.div`
  width: 32%; /* Três cards por linha */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  background: #fff;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
  padding: 2rem;
  gap: 1rem;

  .professional-name {
    font-weight: 600;
    font-size: 1.8rem;
    color: #09090b;
  }

  .personal-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .personal-img img {
    width: 100%;
    height: 210px;
    border-radius: 10px;
    object-fit: cover;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  .to-profile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #15803d;
    border-radius: 8px;
    font-weight: bold;
    padding: 1rem;

    @media only screen and (max-width: 768px) {
      width: 100%; /* Define um limite máximo de largura */
      height: 100%;
      padding: 2rem;
    }
  }

  .see-on-map {
    background-color: #fff;
    color: #15803d;
    font-weight: normal;
  }

  @media only screen and (max-width: 768px) {
    width: 100%; /* Define um limite máximo de largura */

    a {
      padding: 2rem;
      background-color: #15803d;
    }

    .see-on-map {
      display: none;
    }
  }
`;

export const Map = styled.div`
  display: flex;
  flex: 1;
  background: #ffffff;

  .mapboxgl-ctrl-geocoder {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
