import styled from 'styled-components';
import bgimg from '../../../images/circle.svg';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 32px;
  gap: 32px;
  background: #efefef;
  font-family: 'Manrope', sans-serif;

  svg {
    color: #53d574;
  }

  .top-menu {
      display: none;
    }

  @media (max-width: 1024px) {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;

    .top-menu {
          display: block;
          z-index: 20;
        }

      svg {
        width: 34px;
        height: 34px;
        color: #3d3d3d;
      }
    }
`;

export const LSideBar = styled.aside`
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px;

  .logo {
    margin: auto;
    width: 120px;
    height: auto;
  }

  li {
    text-transform: capitalize;
  }

  @media (max-width: 1024px) {
    width: 100%;
    }
`;

export const Menu = styled.div`
  width: 100%;
  height: 100%;
  background: #fefefede;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ul {
    width: 100%;
    margin: 32px 0;
  }

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin: 10px 32px;
    font-size: 14px;
    font-weight: bold;
    color: #989898;
    cursor: pointer;
    transition: ease-in .2s;
    padding: 1rem;
    border-radius: 8px;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 2rem;
    }

    &:hover {
      color: #e1e8ec;
      background: #3a4450;
    }
  }

  .active {
      color: #3d3d3d;
  }

  #Danger {
    color: #CD5656;

    svg {
    color: #CD5656;
    }
  }

  .divider {
    width: 70%;
    height: 2px;
    background: #53d574;

    &::after {
      content: ''
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    }
`;

export const UpgradePlan = styled.div`
  width: 100%;
  height: 22rem;
  background-image: url(${bgimg});
  background-color: #fefefede;
  background-repeat: no-repeat;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  transition: .2s ease-in;

  span {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  strong {
    font-size: 2rem;
    color: #3d3d3d;
    font-weight: bold;
    }

  p {
    font-size: 1.6rem;
    color: #FFFFFF;
    font-weight: bold;
  }

  img {
    width: 8rem;
    height: 8rem;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(.9);
  }

  @media (max-width: 1274px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    background-image: url(${bgimg});
    background-color: #fefefede;
    background-repeat: repeat;
    }
`;

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 24px;
  color: #3d3d3d;

  .searchbox-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
      display: flex;
      width: 100%;
      gap: 24px;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        list-style: none;
        background: #fefefede;
        width: 92px;
        height: 72px;
        transition: ease-in .2s;
        cursor: pointer;

        &:hover {
          color: #e1e8ec;
          background: #3a4450;
        }

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
      .actions {
        display: none;
      }
    }
`;

export const SearchBox = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  padding: 25px 30px;
  align-items: baseline;
  background: #fefefede;
  border-radius: 20px;
  transition: ease-in .2s;
  cursor: pointer;
  color: #3d3d3d;

  h2 {
    text-transform: capitalize;
  }

  p {
    margin-right: .5rem;
  }
`;

export const Metrics = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  .metrics-container {
    width: 100%;
    display: flex;
    gap: 24px;
    }

  .card {
    width: 100%;
    height: 152px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    transition: .2s ease-in;

    &:hover {
      cursor: pointer;
      box-shadow: 4px 24px 45px -20px rgba(31,154,63,.1);
    }

    span {
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #3d3d3d;
    }

    span svg {
      margin-right: 1rem;
      width: 24px;
      height: auto;
    }

    strong {
      font-size: 27px;
      text-transform: uppercase;
      color: #3d3d3d;
    }
}

  .finance,
  .expense {
    width: 50%;
    background: #fefefede;
    color: #1f9a3f;
  }

  .card-graphic {
    height: 331px;
    box-shadow: none;
    font-size: 14px;
  }


    .help-text {
      font-weight: lighter;
      margin-top: .8rem;
      font-size: 12px;
      color: #989898!important;
    }

    .badge {
      padding: .5rem 1rem;
      border: 1px solid #989898;
      border-radius: 8px;
      color: #3d3d3d!important;
    }

      @media (max-width: 1024px) {
        width: 100%;

        .metrics-container {
        display: none;
      }

      .card {
        width: 100%;
      }
    }
`;

export const Dataset = styled.div`
  width: 100%;
  height: 100%;
  padding: 27px 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #fefefede;
  border-radius: 20px;
  color: #3d3d3d;

  table{
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    border-collapse: collapse;
  }

  td, th {
    text-align: left;
    padding: 10px;
    width: 23%;
  }

  th {
    color: #989898;
  }

  tr {
    transition: ease-in .2s;
  }

  tr:nth-child(1n+2):hover {
    color: #e1e8ec;
    background: #3a4450;
    cursor: pointer;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 16px;
    width: 100%;

    img {
      width: 27px;
      height: 27px;
      border-radius: 100%;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    display: none;
    }
`;

export const RSideBar = styled.aside`
  width: 326px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px;

  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    }
`;

export const Profile = styled.div`
  width: 100%;
  padding: 30px;
  background: #fefefede;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  img {
    width: 96px;
    height: 96px;
    object-fit: cover;
    border-radius: 100%;
    border: 2px solid #1f9a3f;
    padding: 4px;
  }

  .divider {
    width: 100%;
    height: 2px;
    background: #53d574;
    margin-bottom: 40px;

    &::after {
      content: ''
    }
  }
`;

export const User = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      font-weight: 900;
      color: #3d3d3d;
      text-transform: capitalize;
    }

    .actions {
      width: 100%;

    ul {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      margin: 24px 0 40px 0;
    }

    li {
      list-style: none;
      background: #f1fcf3;
      width: 70px;
      height: 59px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      cursor: pointer;
      transition: ease-in .2s;

      &:hover {
        filter: brightness(.9)
      }
    }
  }

  .tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-image: url(${bgimg});
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  padding: .5rem;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

    strong {
      padding-top: 8px;
      font-size: 16px;
      color: #3d3d3d;
    }

    p {
      font-weight: bold;
      font-size: 12px;
      color: #989898;
    }
`;

export const About = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  gap: 8px;

    strong {
      font-size: 14px;
      color: #3d3d3d;
    }

    p {
      font-size: 12px;
      color: #989898;
      line-height: 24px;
    }
`;

export const StatusAccont = styled.div`
  width: 100%;
  height: 100%;
  line-height: 24px;
  font-size: 12px;

  padding: 30px;
  background: #fefefede;
  border-radius: 20px;

  strong {
    font-weight: 900;
    color: #3d3d3d;
    font-size: 14px;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    gap: 20px;
  }

  p {
    font-weight: bold;
    color: #989898;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    gap: 24px;

      li {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 20px;

      svg {
        width: 24px;
        height: 24px;
        color: #fabe25;
      }
    }
  }


`;
