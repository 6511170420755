import styled from 'styled-components';

export const Container = styled.div`
  h1, h2, h3, h4, h5 {
    font-family: 'Poppins', sans-serif;
    color: var(--grey-900);
    line-height: 25px;
  }

  background: #fff;
  margin: 0;
  padding: 0;
  color: #1c3438;
  overflow-x: hidden;

  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: space-between;
  align-items: center;

  small {
    padding: 1rem 0;
  }

  .sidebar,
  .mapa {
    width: 50vw;
    height: 100vh;
  }

  .mapa {
    flex: 1;
  }

  .sidebar {
    padding: 3rem;

    width: 50%;
  }

  .sidebar .filters {
    margin: 2rem 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    select,
    input {
      color: rgb(119, 119, 119);
      margin-right: 2rem;
      padding: 1rem;
      border-radius: 8px;
      width: 100%;
      border: 1px solid #ddd;
      background: rgb(248,248,252);
      outline: 0;
      cursor: pointer;
    }

    select:last-child {
      margin-right: 0;
    }
  }

  .topbar {
    background: #fff;
    width: 50%;
    top: 0;
    left: 0;
    padding: 2rem;
    position: fixed;

    a {
      display: flex;
      align-items: center;
    }

    .topbar-row {
      margin-top: 1.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;



    select {
      background: inherit;
      outline: 0;
      padding-top: 0.5rem;
      margin-left: .3rem;
      font-size: 2.2rem;
      font-weight: bold;
      width: fit-content;
    }

    }

    .cityInList {
      border: 0;
      margin-left: .3rem;
      background: inherit;
      font-size: 2.5rem;
      outline: 0;
    }
  }

  .sidebar .topbar button {
    margin-top: 1rem;
      height: 6rem;
      width: 100%;
      cursor: pointer;
      border-radius: 8px;
      color: #fff;
      transition: .2s;
      background: rgb(4,211,97);
      font-weight: bold;
      bottom: 0;

      &:hover {
      background: rgb(4,211,97, .8);
      }
  }

  .list {
    margin-top: 34rem;
    width: 100%;

    display: flex;
    justify-content: space-between;
  }

  .list-card {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
  }

  .card {
    width: 47%;
    padding: 2rem ;
    border: 1px solid #ddd;
    border-radius: 10px;
    line-height: 2.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .2s;
    box-shadow: 0 2px 9px 0 rgb(158 184 209 / 41%);


    &:hover {
      background: rgb(4,211,97, .1);
      transition: .2s;
      border-color: rgb(4,211,97, 1);
      cursor: pointer;
      }

      > img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      margin-top: 3rem;
    }

    .badge-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 1rem;
      height: 40px;

      color: rgb(5,150,105);
      background: rgb(240,255,244);
      border-radius: 50px;

      & img {
        width: 35px;
        height: 35px;
      }
    }

    .npro {
      color:rgb(28, 52, 56);
      background: transparent;
      height: 0px;

    }

    strong {
      font-size: 2rem;
    }



    .name {
      font-size: 1.7rem;
      font-weight: 700;
    }

    .occupation {
      margin-top: 1.35rem;
      font-size: 1.4rem;
    }

    .price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.35rem;
      bottom: 0;

      strong {
        font-size: 1.7rem;
      }
    }

    .inline-btn {
      width: 100%;
      display: flex;
      gap: 1rem;

    }

    #profile {
      background: transparent;
      color: rgb(4,211,97);
      border: 1px solid rgb(4,211,97);
    }

    button {
      margin: 1rem 0;
      height: 5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      cursor: pointer;
      border-radius: 8px;
      color: #fff;
      transition: .2s;
      background: rgb(4,211,97);
      font-weight: bold;
      font-size: 1.3rem;
      bottom: 0;

      &:hover {
      background: rgb(4,211,97, .8);
      }
    }

    .neighborhood {
      font-size: 1.35rem;
      line-height: 1.7rem;
      margin: 1.5rem 0;
      height: 5rem;
      color: rgb(119, 119, 119);
      text-align: center;
    }
  }

  hr {
  margin: 2.5rem 0;
  border: .5px solid #ddd;
  height: 1px;
  padding: 0;
}

  .mapa {
    background: rgb(4,211,97, .1);
    position: fixed;
    right: 0;
    flex: 1;
  }

  @media (max-width: 1024px) {

    .mapa {
      display: none;
    }

    .sidebar {
      display: flex;
      flex-direction: column;
      width: 100%;

      small {
        font-size: 2rem;
      }

      .topbar {
        position: relative;
        width: 100%;
        height: 0;
      }

      .topbar-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .list-card {
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        .card {
          margin-top: 2rem;
          width: 90%;

          .occupation,
          .name,
          .neighborhood,
          .price {
            font-size: 2rem;
            line-height: 3.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 830px) {
    .mapa {
      display: none;
    }

    .sidebar {
      display: flex;
      flex-direction: column;
      width: 100%;

      small {
        font-size: 2rem;
      }

      .topbar {
        position: relative;
        width: 100%;
        margin-bottom: 10rem;
        height: 0;

        .filters {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          flex-wrap: wrap;
        }
        #city {
         width: 100%;
        }
        select {
          margin: 0;
          padding: 0;
          width: 48%;
          height: 45px;
        }

        select:nth-child(3),
        select:nth-child(4) {
          margin-top: 2rem;
        }
      }

      .list-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        .card {
          margin-top: 5rem;
          width: 90%;

          .occupation,
          .name,
          .neighborhood,
          .price {
            font-size: 2rem;
            line-height: 3.5rem;
          }
        }
      }
    }
  }
`;

export const InformationContainer = styled.div`
  width: 25rem;
  margin: 2rem;
  padding: 0rem;
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  color: #1c3438;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  line-height: 2.5rem;
  text-align: center;

  span {
    font-size: 2rem;
    line-height: 3rem;
  }

  strong {
    margin: 1rem 0;
    font-size: 2.2rem;
    line-height: 2.5rem;
    text-align: center;
    color: #1c3438;
  }

  > img {
    margin: 2.5rem 0;
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
  }

  button {
      margin-top: 1rem;
      height: 6rem;
      width: 100%;
      cursor: pointer;
      border-radius: 8px;
      color: #fff;
      transition: .2s;
      background: rgb(4,211,97);
      font-weight: bold;
      bottom: 0;

      &:hover {
      background: rgb(4,211,97, .8);
      }
    }

`;
