import React, { useRef, useCallback } from 'react';
import { FiLogIn } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationsErrors';
import { Link } from 'react-router-dom';

import Input from '../../components/Input';
import Button from '../../components/Button';

import logoImg from '../../images/logo-cuid.png';
import * as S from './styles';
interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Informe seu email')
            .email('Digite um email válido'),
          password: Yup.string().required('Informe sua senha'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [addToast, signIn],
  );
  return (
    <S.Container>
      <S.Content>
        <S.AnimationContainer>
          <img src={logoImg} alt="Logo da plataforma Cuid" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Bem-vindo</h1>

            <Input name="email" type="email" placeholder="E-mail" />

            <Input name="password" type="password" placeholder="Senha" />

            <Button type="submit">Entrar</Button>

            <Link to="/recuperar-senha">Esqueci minha senha</Link>
          </Form>
          <Link to="/profissionais/cadastro">
            <FiLogIn />
            Criar conta
          </Link>
        </S.AnimationContainer>
      </S.Content>
    </S.Container>
  );
};

export default SignIn;
