import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #242d60;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Helvetica Neue', 'Ubuntu', sans-serif;
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const Section = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 112px;
  border-radius: 6px;
  justify-content: space-between;
`;


export const Product = styled.div`
  display: flex;


  > img {
    border-radius: 6px;
    margin: 10px;
    width: 80px;
    height: 80px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;



p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;
  color: #242d60;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}



  h1 {
    color: #242d60;
  }

  h3,
  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: #242d60;
    margin: 0;
  }

  h5 {
    opacity: 0.5;
  }
`;

export const Button = styled.button`
  height: 36px;
  background: #556cd6;
  color: white;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  border-radius: 0 0 6px 6px;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);


  &:hover {
  opacity: 0.8;
}
`;
