import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import { ToastProvider } from './toast';

interface User {
  id: string;
  slug: string;
  name: string;
  avatar_url: string;
  bio: string;
  email: string;
  whatsapp: string;
  cpf: string;
  zip: string;
  address: string;
  numberHouse: string;
  neighborhood: string;
  city: string;
  uf: string;
  longitude: number;
  latitude: number;
  birthday: string;
  occupation: string;
  cost: number;
  payment_status: boolean;
  availability: string;
  formation: string;
  genre: string;
  verified: boolean;
  created_at: string;
}

interface AuthState {
  caregiver: User;
  token: string;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateCaregiver(caregiver: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

// eslint-disable-next-line react/prop-types
const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Cuid:token');
    const caregiver = localStorage.getItem('@Cuid:user');

    if (token && caregiver) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, caregiver: JSON.parse(caregiver) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', {
      email,
      password,
    });

    const { token, caregiver } = response.data;

    localStorage.setItem('@Cuid:token', token);
    localStorage.setItem('@Cuid:user', JSON.stringify(caregiver));

    setData({ token, caregiver });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Cuid:token');
    localStorage.removeItem('@Cuid:user');

    setData({} as AuthState);
  }, []);

  const updateCaregiver = useCallback(
    async (caregiver: User) => {
      localStorage.setItem('@Cuid:user', JSON.stringify(caregiver));

      setData({
        token: data.token,
        caregiver,
      });
    },
    [data.token],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.caregiver, signIn, signOut, updateCaregiver }}
    >
      <ToastProvider>{children}</ToastProvider>
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
