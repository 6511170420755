import styled from 'styled-components';

export const LSideBar = styled.aside`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px;

  .logo {
    margin: auto;
    width: 120px;
    height: auto;
  }

  li {
    text-transform: capitalize;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Menu = styled.div`
  width: 100%;
  height: 100%;
  background: #fefefede;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ul {
    width: 100%;
    margin: 32px 0;
  }

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin: 10px 32px;
    font-size: 14px;
    font-weight: bold;
    color: #989898;
    cursor: pointer;
    transition: ease-in 0.2s;
    padding: 1rem;
    border-radius: 8px;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 2rem;
    }

    &:hover {
      color: #e1e8ec;
      background: #3a4450;
    }
  }

  .active {
    color: #3d3d3d;
  }

  #Danger {
    color: #cd5656;

    svg {
      color: #cd5656;
    }
  }

  .divider {
    width: 70%;
    height: 2px;
    background: #53d574;

    &::after {
      content: '';
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
  }
`;
